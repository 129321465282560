import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';


import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import COVID19_PCR from '../../COVID19_PCR/Covid19_PCR.js'
import Emirates from './AirLineSection.js'
// 26-june-2023
import AQ from "../../../assets/img_stock/New2023/icon/Icon1.png"
import CP from "../../../assets/img_stock/New2023/icon/Icon3.png"
import GSS from "../../../assets/img_stock/New2023/icon/Icon2.png"
import IL from "../../../assets/img_stock/New2023/icon/Icon4.png"
import AD from "../../../assets/img_stock/New2023/icon/Icon5.png"
import PCS from "../../../assets/img_stock/New2023/icon/Icon6.png"
//6-jul-2023
import useWindowDimensions from "../../../useWindowDimensions";









import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return (
    <div className={classes.section} style={{padding:width<500?"0%":"10px 0"}}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginLeft:"2%",marginRight:"2%"}}>
          
          {/* <h2 className={classes.title}>Metropole Laboratories (Pvt) Ltd.</h2> */}
        
          <p className={classes.description} style={{textAlign:"justify",fontWeight:"bold",marginLeft:width<500?"5%":"0%",marginRight:width<500?"5%":"0%",color:"#3C4858",marginTop:20}}>
          <span style={{fontWeight:"bolder",fontSize:15,color:"#18698d"}}>
            Metropole Laboratories Private Ltd,</span> a state-of-the-art diagnostic facility spanning over 10,000 square feet in the capital city Islamabad, accompanied by an expanding network of service centers. We own regulatory
licenses from Islamabad Healthcare Regulatory Authority, Islamabad (IHRA) and Pakistan Nuclear
Regulatory Authority (PNRA). We take pride in being the only diagnostic company in Pakistan to
achieve the prestigious ISO-15189 accreditation from the Pakistan National Accreditation Council
(PNAC) within a short span of time since our launch. We also hold the ISO 9001:2015 certification
which reinforces our commitment to continuous improvement across all aspects of our operations.
We continuously invest in offering world's leading diagnostic technologies in our core laboratory,
radiology and imaging facilities. Our dedicated and exceptionally qualified team of consultants,
scientists & allied healthcare professionals ensure constant compliance with global healthcare
standards. We participate in internationally monitored external quality assurance programs along
with a robust daily QC monitoring system to ensure the accuracy and reliability of every service we
provide. Our customized LMIS with features like QR-based robotic pre-analytical management,
bidirectional interfacing of analyzers, KPI monitoring dashboards, and closely monitored & constant
feedback system makes our workflows swifter & smarter. We are committed to provide prompt,
efficient, and patient-centered services, positioning ourselves as the premier diagnostic service
provider for better healthcare management of patients and clients alike.
          </p>
        </GridItem>
        </GridContainer>
       
            {/* <COVID19_PCR /> */}

     
      
     {/* <br /> */}
        <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={4}>
            <InfoArea
              title="Advanced Diagnostic Technologies"
              description="At Metropole, we are staying at the forefront of cutting-edge technologies by having advanced & innovative technologies in pathology & radiology departments. "
              icon={AD}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <InfoArea
              title="Innovative LIMS"
              description="MPL has in placed advanced laboratory information and management systems to ensure secure handling of patient information & swifter process flows to offer best TAT for every service. "
              icon={IL}
              iconColor="info"
              vertical
            />
          </GridItem>
         
         
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <InfoArea
              title="Global Standard of Services"
              description="MPL is determined to follow all relevant national & international regulations and standards in the medical laboratory industry. We hold licenses from both IHRA and PNRA, along with ISO 9001:2015 certification and ISO 15189:2012 accreditation by PNAC."
              icon={GSS}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12}  md={6} lg={4}>
            <InfoArea
              title="Assured Quality"
              description="We participate in internationally monitored external quality assurance programs along with a robust daily QC monitoring system to ensure the accuracy and reliability of every service we provide."
              icon={AQ}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12}  md={6} lg={4}>
            <InfoArea
              title="Competitive Prices"
              description="MPL offers exceptional quality of services at competitive and affordable prices which are usually 25 to 30% lesser from other diagnostic service providers, offering best value against the fee you pay."
              icon={CP}
              iconColor="danger"
              vertical
            />
          </GridItem>
         
          <GridItem xs={12} sm={12}  md={6} lg={4}>
            <InfoArea
              title="Patient-Centered Services "
              description="We are dedicated to earn the trust and loyalty of our patients by prioritizing their comfort and satisfaction. Our passion for ensuring that every patient receives the highest quality of care and support drives us to continuously strive for excellence."
              icon={PCS}
              iconColor="success"
              vertical
            />
          </GridItem>
        
        
        </GridContainer>
        <br />
        {/* aik me he kr dya hai phly 3 ,3 alag thy now 6 in one */}
        {/* <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Assured Quality"
              description="We participate in internationally monitored external quality assurance programs along with a robust daily QC monitoring system to ensure the accuracy and reliability of every service we provide."
              icon={AQ}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Competitive Prices"
              description="MPL offers exceptional quality of services at competitive and affordable prices which are usually 20 to 25% lesser from other diagnostic service providers, offering best value against the fee you pay."
              icon={CP}
              iconColor="danger"
              vertical
            />
          </GridItem>
         
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Patient-Centered Services "
              description="We are dedicated to earning the trust and loyalty of our patients by prioritizing their comfort and satisfaction. Our passion for ensuring that every patient receives the highest quality of care and support drives us to continuously strive for excellence."
              icon={PCS}
              iconColor="success"
              vertical
            />
          </GridItem>
        
        </GridContainer> */}
         {/* <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'50%',marginTop:"5%"}}>
          <img
                src={require("assets/img/building.jpeg")}
                style={{ 
                maxWidth: "60%",
                maxHeight:'30%',
                height: "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
          </GridItem>
      </GridContainer> */}
     
    </div>
  );
}
