/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload, ImportantDevices } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import url from '../../link'
import MplProfile from "../../assets/pdf/MplProfileNew.pdf"
import AutoimmuneDiagnostics from "../../assets/pdf/AutoimmuneFlyer.pdf"
import Echoflyer from "../../assets/pdf/Echoflyer.pdf"
import Fibroscan from "../../assets/pdf/FibroscanFlyer.pdf"
import OPG from "../../assets/pdf/OPGFlyer.pdf"


const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  // if patient login then display this main menu 
  var Main_Menu= localStorage.getItem('role')=='Patient' ?
  [ 
  // view => landingpage=>find a test
  <NavLink to="/FindTest" className={classes.dropdownLink}>
  Find a Test
  </NavLink>,
  // view => labReports=>labReports
  <NavLink to="/Lab_Reports" className={classes.dropdownLink}>
Lab Reports
</NavLink>,
<NavLink to="/COVID19_HomeSampling"
// style={{
//   backgroundColor:'red',
//   color:'white',
//   padding:5,
//   fontWeight:'bold'
// }}
// these classes are called from this path import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
className={classes.dropdownLink}>
Book COVID-19 Free Home Sampling
</NavLink>,
<NavLink to="/HomeSampling" className={classes.dropdownLink}>
Book Free Home Sampling
</NavLink>,
<NavLink to="/Booking_History" className={classes.dropdownLink}>
Booking History
</NavLink>,
<NavLink to="/ResetPassword" className={classes.dropdownLink}>
Reset Password
</NavLink>]
:
[<NavLink to="/FindTest" className={classes.dropdownLink}>
Find a Test
</NavLink>,
<NavLink to="/Lab_Reports" className={classes.dropdownLink}>
Lab Reports
</NavLink>,
<NavLink to="/ResetPassword" className={classes.dropdownLink}>
Reset Password
</NavLink>]

  var ksa = localStorage.getItem('patient') ?
  (!localStorage.getItem('patient_name').toLowerCase().includes("mpl-") && (localStorage.getItem('patient_id')=="najam" || localStorage.getItem('patient_id')=="najam1" ))  ? 
  [<NavLink to="/KSA_Reports" className={classes.dropdownLink}>
  KSA Summary
</NavLink>,
<NavLink to="/Overall_Covid_Reports" className={classes.dropdownLink}>
Overall Covid-19 Summary
</NavLink>
,
<NavLink to="/ExportKSACases" className={classes.dropdownLink}>
KSA Positive Cases
</NavLink>
,
<NavLink to="/QatarExcelExport" className={classes.dropdownLink}>
Qatar Airways Summary
</NavLink> 
,
<NavLink to="/Bookings" className={classes.dropdownLink}>
Booking Counter
</NavLink>
,
<NavLink to="/Overall_Audit" className={classes.dropdownLink}>
Audit of Passenger cases (24 Hours) Destination Wise
</NavLink>
,
<NavLink to="/Travelers" className={classes.dropdownLink}>
Travelers Details Export
</NavLink>
,    

<NavLink to="/FlyDubaiExcelExport" className={classes.dropdownLink}>
FlyDubai Summary
</NavLink>
,

<NavLink to="/AirBlue_Export" className={classes.dropdownLink}>
AirBlue Summary Export
</NavLink>
,
// 05-sep-2023 its not working thats why hide
//  <NavLink to="/View_Sample_Tracking" className={classes.dropdownLink}>
//  View Sample Tracking
//  </NavLink>
//  ,
 <NavLink to="/GerryOnlyExcelExport" className={classes.dropdownLink}>
 Excel Export of Gerry
   </NavLink>
   ,  
 <NavLink to="/GerrysExcelExport" className={classes.dropdownLink}>
 Gerry's Summary
 </NavLink>
] 
:
( localStorage.getItem('patient_name').toLowerCase().includes("mpl-")  && localStorage.getItem('role')=='Admin' )
?
[ ]

:[]:[]

  return (
    <List className={classes.list}>
      
      {/* Home for all Header that are honePage in index.js*/}
      <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#196c90', 
          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
          
        >
          <NavLink to="/" 
          style={{color:'white'}}
          >Home </NavLink>
        </Button>
      </ListItem>
        {/* Home for all Header that are honePage in index.js*/}
       

{/* 2-sep-2023 About us Patient ko b nazar aae */}
      {/* {
        localStorage.getItem('role')!='Patient' ?
        <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center'}}>
        <CustomDropdown
          buttonText="About Us"
          style={{backgroundColor:'#196c90 !important'}}
          className={classes.navLink}
          dropdownList={[
  //           <NavLink to="/WhoWeAre" className={classes.dropdownLink}>
  //  Who We Are
  //           </NavLink>,
  <NavLink to="/CeoMessage" 
  className={classes.dropdownLink}
  >CEO's Message </NavLink>,
  <NavLink to="/OurMission" className={classes.dropdownLink}>
  Mission & Vision
               </NavLink>,
                   
  <NavLink to="/OurTeam" 
  className={classes.dropdownLink}
  >Team </NavLink>,
  <NavLink to="/NewsAndFeed" 
  className={classes.dropdownLink}
  >News & Feed </NavLink>,
  <NavLink to="/OurGallery" 
  className={classes.dropdownLink}
  >Gallery </NavLink>,
       
            
                  // <NavLink to="/CompanyProfile" className={classes.dropdownLink}>
                  // Company Profile
                  //              </NavLink>,
            // <a  className={classes.dropdownLink} href={MplProfile} download="MPL-Profile.pdf">Download Mpl Profile</a>
           
              

          ]}
        />
      </ListItem>
      :null
      } */}
      <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center'}}>
        <CustomDropdown
          buttonText="About Us"
          style={{backgroundColor:'#196c90 !important'}}
          className={classes.navLink}
          dropdownList={[
  //           <NavLink to="/WhoWeAre" className={classes.dropdownLink}>
  //  Who We Are
  //           </NavLink>,
  <NavLink to="/CeoMessage" 
  className={classes.dropdownLink}
  >CEO's Message </NavLink>,
  <NavLink to="/OurMission" className={classes.dropdownLink}>
  Mission & Vision
               </NavLink>,
                   
  <NavLink to="/OurTeam" 
  className={classes.dropdownLink}
  >Our Team </NavLink>,
  <NavLink to="/NewsAndFeed" 
  className={classes.dropdownLink}
  >News & Feed </NavLink>,
  <NavLink to="/OurGallery" 
  className={classes.dropdownLink}
  >Gallery </NavLink>,
       
            
                  // <NavLink to="/CompanyProfile" className={classes.dropdownLink}>
                  // Company Profile
                  //              </NavLink>,
            // <a  className={classes.dropdownLink} href={MplProfile} download="MPL-Profile.pdf">Download Mpl Profile</a>
           
              

          ]}
        />
      </ListItem>
    
      {/* NewsAndFeeds 31-dec-2022 */}
      {/* <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#196c90',
          // boxShadow:" 5px 5px #cbcccd"
          // boxShadow:" 5px 5px #faa931"

        }}
          
        >
          <NavLink to="/NewsAndFeed" 
          style={{color:'white'}}
          >News & Feed </NavLink>
        </Button>
      </ListItem> */}

      {/* Printing 08-march-2023 */}
      {/* <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#196c90'}}
          
        >
          <NavLink to="/Printing" 
          style={{color:'white'}}
          >Print </NavLink>
        </Button>
      </ListItem> */}
     {/* Service part in Header that are drop down when we click its move to that screen define in index.js "/Clinic_Chemistry&Immunology" etc */}
     
      <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center',}}>
        <CustomDropdown
          buttonText="SERVICES"
          style={{backgroundColor:'#196c90',
        }}
          className={classes.navLink}
      
          dropdownList={[
            <NavLink to="/Clinic_Chemistry&Immunology" className={classes.dropdownLink}>
   Clinical Chemistry & Immunology
            </NavLink>,
            <NavLink to="/Hematology" className={classes.dropdownLink}>
            Hematology
                         </NavLink>,
            
              
              <NavLink to="/Microbiology&ClinicalPathology" className={classes.dropdownLink}>
                 Microbiology & Clinical Pathology

                </NavLink>,
                  <NavLink to="/Molecular" className={classes.dropdownLink}>
                  Molecular Pathology & Genetics
                    </NavLink>,
              <NavLink to="/Histopathology" className={classes.dropdownLink}>
                     
Histopathology

                        </NavLink>,
            
                        <NavLink to="/Special_Pathology" className={classes.dropdownLink}>
                        Special Pathology
                          </NavLink>,
                           <NavLink to="/Ultrasound" className={classes.dropdownLink}>
                           Ultrasound
                                        </NavLink>,
                                         <NavLink to="/Echocardiography" className={classes.dropdownLink}>
                                        Echocardiography
                                                      </NavLink>,
                                        <NavLink to="/Fibroscan" className={classes.dropdownLink}>
                                        Fibroscan
                                        </NavLink>,
                                        <NavLink to="/DigitalXray" className={classes.dropdownLink}>
                                        Digital X-Ray
                                        </NavLink>,
                                        <NavLink to="/OPG" className={classes.dropdownLink}>
                                        OPG (Orthopantomogram)
                                        </NavLink>,
                                        <NavLink to="/Spirometry" className={classes.dropdownLink}>
                                        Spirometry 
                                        </NavLink>,
                        //  <NavLink to="/Covid" className={classes.dropdownLink}>
                        // Covid 19 Molecular Lab
                        // </NavLink>,




          ]}
        />
      </ListItem>
   
      {/* if login person are not patient then show */}
      {/* {
        localStorage.getItem('role')!='Patient' ?
        <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center'}}>
        <CustomDropdown
          buttonText="About Us"
          style={{backgroundColor:'#196c90 !important'}}
          className={classes.navLink}
          dropdownList={[
            <NavLink to="/WhoWeAre" className={classes.dropdownLink}>
   Who We Are
            </NavLink>,
             <NavLink to="/OurMission" className={classes.dropdownLink}>
Mission & Vision
             </NavLink>
              

          ]}
        />
      </ListItem>
      :null
      } */}
       {/* if login person are  patient then show patient Id and passworn on diary */}
       {/* 2-sep-2023 ko header se remove kiya */}
      {/* {
        localStorage.getItem('role')=='Patient' ?
        <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
      <Button
        className={'online_reports'}
      >
        
        <NavLink to="/COVID19_HomeSampling" 

        style={{color:'white'}}
      

      >Book COVID-19 Home Sampling</NavLink>
      </Button>
    </ListItem>
      : null
      } */}
      {/* onLine Reports me login ni hona chahaye when click on this go to Login page */}
      {/* {(localStorage.getItem('patient')==null && localStorage.getItem('emirates')==null && localStorage.getItem('qatar')==null && localStorage.getItem('flydubai')==null && localStorage.getItem('onix')==null) && localStorage.getItem('airblue')==null && localStorage.getItem('etihad')==null && localStorage.getItem('OmanAir')==null && localStorage.getItem('SereneAir')==null ? 
      
      <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
      <Button
        className={'online_reports'}
      >
        <NavLink to="/Login" 

        style={{color:'white',
        
      }}
      

        >Online Reports</NavLink>
      </Button>
    </ListItem>
    : <></>
    } */}
    {/* if localStorage have emirates then only show 3 main menu item */}
       {localStorage.getItem('emirates') ?
       
       <>
       <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
     <CustomDropdown
       buttonText="MENU"
       style={{backgroundColor:'#196c90'}}
       className={classes.navLink}
       dropdownList={[
         <NavLink to="/FindTest" className={classes.dropdownLink}>
         Find a Test
         </NavLink>,

//  /summary in view =>Emirates_KSA_Summary =>Summary
          <NavLink to="/Summary" className={classes.dropdownLink}>
          COVID-19 by PCR
          </NavLink>,
<Button 
style={{textAlign:'center', width:'100%',}}
onClick={()=>{
 localStorage.clear()
 setTimeout(()=>{
   window.location.reload()
 },1000)
 window.location.href=url

}}
className={classes.dropdownLink}>
Logout
</Button>


       ]}
     />
   </ListItem>
    
   </>
   :
   localStorage.getItem('flydubai') ?
   <>
    {/* if localStorage have flydubai then only show 3 main menu item */}

       <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
     <CustomDropdown
       buttonText="MENU"
       style={{backgroundColor:'#196c90'}}
       className={classes.navLink}
       dropdownList={[
         <NavLink to="/FindTest" className={classes.dropdownLink}>
         Find a Test
         </NavLink>,

          <NavLink to="/FlyDubai_Covid19_PCR" className={classes.dropdownLink}>
          COVID-19 by PCR
          </NavLink>,
<Button 
style={{textAlign:'center', width:'100%',}}
onClick={()=>{
 localStorage.clear()
 setTimeout(()=>{
   window.location.reload()
 },1000)
 window.location.href=url

}}
className={classes.dropdownLink}>
Logout
</Button>


       ]}
     />
   </ListItem>
    
   </>
   :
   localStorage.getItem('airblue') ?
   <>
       <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
     <CustomDropdown
       buttonText="MENU"
       style={{backgroundColor:'#196c90'}}
       className={classes.navLink}
       dropdownList={[
         <NavLink to="/FindTest" className={classes.dropdownLink}>
         Find a Test
         </NavLink>,


          <NavLink to="/AirBlue_COVID19_PCR" className={classes.dropdownLink}>
          COVID-19 by PCR
          </NavLink>,
<Button 
style={{textAlign:'center', width:'100%',backgroundColor:"blue"}}
onClick={()=>{
 localStorage.clear()
 setTimeout(()=>{
   window.location.reload()
 },1000)
 window.location.href=url

}}
className={classes.dropdownLink}>
Logout
</Button>


       ]}
     />
   </ListItem>
    
   </>
   :
   
   localStorage.getItem('etihad') ?
   <>
       <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
     <CustomDropdown
       buttonText="MENU"
       style={{backgroundColor:'#196c90'}}
       className={classes.navLink}
       dropdownList={[
         <NavLink to="/FindTest" className={classes.dropdownLink}>
         Find a Test
         </NavLink>,


          <NavLink to="/EtihadAirways_COVID19_PCR" className={classes.dropdownLink}>
          COVID-19 by PCR
          </NavLink>,
<Button 
style={{textAlign:'center', width:'100%'}}
onClick={()=>{
 localStorage.clear()
 setTimeout(()=>{
   window.location.reload()
 },1000)
 window.location.href=url

}}
className={classes.dropdownLink}>
Logout
</Button>


       ]}
     />
   </ListItem>
    
   </>
   :
   localStorage.getItem('OmanAir') ?
   <>
       <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
     <CustomDropdown
       buttonText="MENU"
       style={{backgroundColor:'#196c90'}}
       className={classes.navLink}
       dropdownList={[
         <NavLink to="/FindTest" className={classes.dropdownLink}>
         Find a Test
         </NavLink>,


          <NavLink to="/OmanAir_COVID19_PCR" className={classes.dropdownLink}>
          COVID-19 by PCR
          </NavLink>,
<Button 
style={{textAlign:'center', width:'100%'}}
onClick={()=>{
 localStorage.clear()
 setTimeout(()=>{
   window.location.reload()
 },1000)
 window.location.href=url

}}
className={classes.dropdownLink}>
Logout
</Button>


       ]}
     />
   </ListItem>
    
   </>
   :
   localStorage.getItem('SereneAir') ?
   <>
       <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
     <CustomDropdown
       buttonText="MENU"
       style={{backgroundColor:'#196c90'}}
       className={classes.navLink}
       dropdownList={[
         <NavLink to="/FindTest" className={classes.dropdownLink}>
         Find a Test / Service
         </NavLink>,


          <NavLink to="/SereneAir_COVID19_PCR" className={classes.dropdownLink}>
          COVID-19 by PCR
          </NavLink>,
<Button 
style={{textAlign:'center', width:'100%'}}
onClick={()=>{
 localStorage.clear()
 setTimeout(()=>{
   window.location.reload()
 },1000)
 window.location.href=url

}}
className={classes.dropdownLink}>
Logout
</Button>


       ]}
     />
   </ListItem>
    
   </>
   
   :
   localStorage.getItem('onix') ?
   <>
       <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
     <CustomDropdown
       buttonText="MENU"
       style={{backgroundColor:'#196c90'}}
       className={classes.navLink}
       dropdownList={[
         <NavLink to="/FindTest" className={classes.dropdownLink}>
         Find a Test
         </NavLink>,


          <NavLink to="/OnixEnterprises_COVID19_PCR" className={classes.dropdownLink}>
          COVID-19 by PCR
          </NavLink>,
<Button 
style={{textAlign:'center', width:'100%'}}
onClick={()=>{
 localStorage.clear()
 setTimeout(()=>{
   window.location.reload()
 },1000)
 window.location.href=url

}}
className={classes.dropdownLink}>
Logout
</Button>


       ]}
     />
   </ListItem>
    
   </>
   :
    localStorage.getItem('qatar') ?
    <>
        <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
      <CustomDropdown
        buttonText="MENU"
        style={{backgroundColor:'#196c90'}}
        className={classes.navLink}
        dropdownList={[
          <NavLink to="/FindTest" className={classes.dropdownLink}>
          Find a Test
          </NavLink>,
 
 
           <NavLink to="/Qatar_COVID19_PCR" className={classes.dropdownLink}>
           COVID-19 by PCR
           </NavLink>,
 <Button 
 style={{textAlign:'center', width:'100%'}}
 onClick={()=>{
  localStorage.clear()
  setTimeout(()=>{
    window.location.reload()
  },1000)
  window.location.href=url
 
 }}
 className={classes.dropdownLink}>
 Logout
 </Button>
 
 
        ]}
      />
    </ListItem>
     
    </>
   :
       
       localStorage.getItem('patient') && localStorage.getItem('role')!='Admin' ? 
         <>
          <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
        <CustomDropdown
          buttonText="MENU"
          style={{backgroundColor:'#196c90'}}
          className={classes.navLink}
          dropdownList={[
            // ye menu oper define hn for patient 27
           ...Main_Menu,
<Button 
style={{textAlign:'center', width:'100%',height:"25px"}}
  onClick={()=>{
    localStorage.clear()
    setTimeout(()=>{
      window.location.reload()
    },1000)
    window.location.href=url
   
  }}
className={classes.dropdownLink}>
  Logout
</Button>


          ]}
        />
      </ListItem>
       
      </>
      :
      // IF login person are Admin and patient both 
      localStorage.getItem('patient') && localStorage.getItem('role')=='Admin' ?
      <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
      <CustomDropdown
        buttonText="MENU"
        style={{backgroundColor:'#196c90'}}
        className={classes.navLink}
        dropdownList={[
          <NavLink to="/FindTest" className={classes.dropdownLink}>
          Find a Test / Service
          </NavLink>,
          <NavLink to="/Lab_Reports" className={classes.dropdownLink}>
Lab Reports
</NavLink>,


,
...ksa
,
<Button 
style={{textAlign:'center', width:'100%',height:"25px"}}
onClick={()=>{
  localStorage.clear()
  setTimeout(()=>{
    window.location.reload()
  },1000)
  window.location.href=url
 
}}
className={classes.dropdownLink}>
Logout
</Button>


        ]}
      />
    </ListItem>
      :
      // ye pateint admin k ilawa sab k lye jo website open kre ga
      <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
      <CustomDropdown
        buttonText="MENU"
        style={{backgroundColor:'#196c90'}}
        className={classes.navLink}
        dropdownList={[
          <NavLink to="/FindTest" className={classes.dropdownLink}>
          Find a Test / Service
          </NavLink>, 
  

        ]}
        // get a qoute k nichy se cut kiye hn 
//         <NavLink to="/QatarLogin" className={classes.dropdownLink}>
// Qatar Airways
// </NavLink>,
// <NavLink to="/Emirates" className={classes.dropdownLink}>
// Emirates
// </NavLink>,
// <NavLink to="/FlyDubai" className={classes.dropdownLink}>
// FlyDubai
// </NavLink>,
// <NavLink to="/AirBlue" className={classes.dropdownLink}>
// Air Blue
// </NavLink>,
// <NavLink to="/SereneAir" className={classes.dropdownLink}>
// Serene Air
// </NavLink>,
// <NavLink to="/OmanAir" className={classes.dropdownLink}>
// Oman Air
// </NavLink>,
// <NavLink to="/Onix_Enterprises" className={classes.dropdownLink}>
// Onix Enterprises
// </NavLink>
      />
    </ListItem>
      
      }
         {
        localStorage.getItem('role')=='Patient' ?
        <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
        <Button
         className={'online_reports'}
          style={{backgroundColor:'#196c90', 
          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
          
        >
          <NavLink to="/Lab_Reports" 
          style={{color:'white'}}
          >Lab Reports </NavLink>
        </Button>
      </ListItem>:<></>}
        {/* NewsAndFeeds 31-dec-2022 */}
        {
        localStorage.getItem('role')!='Panel'&& localStorage.getItem('role')!='Patient'  ?
        <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center'}}>
        <CustomDropdown
          buttonText="Download"
          style={{backgroundColor:'#196c90 !important'}}
          className={classes.navLink}
          dropdownList={[
//             <NavLink to="/WhoWeAre" className={classes.dropdownLink}>
//    Who We Are
//             </NavLink>,
//              <NavLink to="/OurMission" className={classes.dropdownLink}>
// Mission & Vision
//              </NavLink>,
//              <NavLink to="/NewsAndFeed" 
//              className={classes.dropdownLink}
//              >News & Feed </NavLink>,
//                   <NavLink to="/CompanyProfile" className={classes.dropdownLink}>
//                   Company Profile
//                                </NavLink>,
            <a  className={classes.dropdownLink} href={MplProfile} download="MPL-Profile.pdf">Company Profile 2023-24</a>,
            <a  className={classes.dropdownLink} href={AutoimmuneDiagnostics} download="AutoimmuneDiagnostics.pdf">Autoimmune Diagnostics</a>
,
<a  className={classes.dropdownLink} href={Echoflyer} download="Echocardiography.pdf">Echocardiography</a>,
            <a  className={classes.dropdownLink} href={Fibroscan} download="Fibroscan.pdf">Fibroscan</a>
            ,
            <a  className={classes.dropdownLink} href={OPG} download="OPG.pdf">OPG</a>

           
              

          ]}
        />
      </ListItem>
      :<></>}
        {(localStorage.getItem('patient')==null && localStorage.getItem('emirates')==null && localStorage.getItem('qatar')==null && localStorage.getItem('flydubai')==null && localStorage.getItem('onix')==null) && localStorage.getItem('airblue')==null && localStorage.getItem('etihad')==null && localStorage.getItem('OmanAir')==null && localStorage.getItem('SereneAir')==null ? 
      
      <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
      <Button
        className={'online_reports'}
        // style={{backgroundColor:"#faa931"}}
      >
        <NavLink to="/Login" 

        style={{color:'#faa931',
        
      }}
      

        >Online Reports</NavLink>
      </Button>
    </ListItem>
    : <></>
    }
    
       {(localStorage.getItem('patient') || localStorage.getItem('emirates') || localStorage.getItem('qatar') ||  localStorage.getItem('flydubai') || localStorage.getItem('airblue') || localStorage.getItem('etihad') || localStorage.getItem('OmanAir') || localStorage.getItem('SereneAir') || localStorage.getItem('onix'))  ? 
       <ListItem className={classes.listItem} style={{textAlign:'center'}}>
       <Button
          style={{backgroundColor:'white',   boxShadow:
          "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
      }}
        
       >
       <img src={require('assets/img/welcome.png')}
       style={{width:'3em'}}
       />
      <b style={{color:'black',fontSize:localStorage.getItem('patient_name')=="Social Security Hospital, Islamabad (Lab Tender No 2292 FY22-23)"?10:""}}> Welcome
      
        <br />{localStorage.getItem('patient_name')==null && localStorage.getItem('emirates') ?  "Emirates" : localStorage.getItem('flydubai') ?
        "FlyDubai" : localStorage.getItem('airblue') ? "Air Blue"  : localStorage.getItem('etihad') ? 'Etihad Airways' : localStorage.getItem('OmanAir') ?  "Oman Air"  :
        localStorage.getItem('qatar') ? "Qatar" : localStorage.getItem('SereneAir') ? "Serene Air" :  localStorage.getItem('onix') ? "Onix Enterprises" 
        :localStorage.getItem('patient_name')}</b>

       </Button>
     </ListItem>
      :<></> }
      
    </List>
  );
}
