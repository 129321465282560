import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
  titleContainer:{
    flexDirection: 'row',
   justifyContent:'start',
   //margin:2,
   //marginTop:0,
  //  top:110,
  left:28.5,
  top:120,
  // left:26,
    // backgroundColor:"green",
    // width:180,
    // borderStyle:"solid",
    // borderBottomColor: 'black',
    // borderBottomWidth: 3,
position:"absolute"
   
},
reportTitle:{
    color: 'black',
    fontSize: 10.8,
    // backgroundColor: '#F2F0F0',
    // border:1,
    fontFamily:'Helvetica-Bold',
    // paddingLeft:5,
    // paddingRight:20,
    //  paddingTop:-15
}
  });


  const InvoiceTitleNewGroup = ({title}) => (
    <View style={styles.titleContainer}>
        {/* <Text style={[styles.reportTitle,{ fontSize:title=="MPL Pre Employment Medical (Riders/Drivers)"?9.7:title=="MPL Pre Employment Medical (General)"?9.7: 10.8,}]}>{title}</Text> */}
        {/* <Text style={[styles.reportTitle,{ fontSize:title=="MPL Pre Employment Medical (Riders/Drivers)"?9.7:title=="MPL Pre Employment Medical (General)"?9.7:title=="MPL Staff Medical (Kitchen)"? 10.8:8.7}]}>{title}</Text> */}
        <Text style={[styles.reportTitle,{ 
          // fontSize:title=="MPL Pre Employment Medical (Riders/Drivers)"?9.7:title=="MPL Pre Employment Medical (General)"?9.7:title=="MPL Staff Medical (Kitchen)"? 10.8:9.7
          fontSize:8.7
         
          
          }]}>{title}</Text>

        {/* <Text style={styles.reportTitle}>{title}</Text> */}
    </View>
  );
  
  export default InvoiceTitleNewGroup