import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import useWindowDimensions from "../../useWindowDimensions";


import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Form from "./Form";
// Sections for this page
const dashboardRoutes = [];

const useStyles = makeStyles(styles);



export default function HomeSampling(props) {
  const classes = useStyles();
  const { width } = useWindowDimensions();
 
  const { ...rest } = props;
  return (
    <div style={{backgroundColor:"#196c90"}} >
      <Header
        // color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      {/* <Parallax filter image={require("assets/img/info.jpeg")} style={{maxHeight:'25em'}}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h2 className={classes.title}>COVID-19 Free Home Samling</h2>
              <h4>
                  COVID-19 Free Home Sampling
              </h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax> */}
         <div  >
         <img
                src={require("assets/img_stock/New2023/backgroundFinal7.jpg")}
                style={{ 
                maxWidth: "100%",
                height:width<500?200 : "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                marginTop:width<500?"22%":"8%",
                opacity:width<500?0.5:0.5
            
            }}
              />
              {/* <h1 className={classes.title} style={{position:"absolute"}}>Message From CEO </h1> */}

        {/* <div className={classes.container}>
          <GridContainer>
            
            <GridItem>
            
           
              <h1 className={classes.title} style={{marginTop:-40}}>Message From CEO </h1>
              
            
            
            </GridItem>
          </GridContainer>
        </div> */}
      </div>
      {/* <div className={classNames(classes.main,classes.mainRaised)}> */}
      <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?-50:-200}}>

        <div className={classes.container} style={{ paddingBottom:"5em"}} >
          {/* show form in Home Sampling */}
            <Form />
          
        </div>
      </div>
      <Footer />
    </div>
  );
}
