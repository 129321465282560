// import React from "react";
import React, { useEffect, Fragment } from 'react';

// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//6-jul-2023
import useWindowDimensions from "../../useWindowDimensions";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionBasics from "./Sections/SectionBasics.js";
import SectionNavbars from "./Sections/SectionNavbars.js";
import SectionTabs from "./Sections/SectionTabs.js";
import SectionPills from "./Sections/SectionPills.js";
import SectionNotifications from "./Sections/SectionNotifications.js";
import SectionTypography from "./Sections/SectionTypography.js";
import SectionJavascript from "./Sections/SectionJavascript.js";
import SectionCarousel from "./Sections/SectionCarousel.js";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
import SectionLogin from "./Sections/SectionLogin.js";
import SectionExamples from "./Sections/SectionExamples.js";
import SectionDownload from "./Sections/SectionDownload.js";
import FreeHS_Msg from './FreeHS_Msg'

import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import TeamSectionNew from "./Sections/TeamSectionNew.js";
import SlickCarousel from 'components/SlickCarousel/SlickCarousel';

import WorkSection from "./Sections/WorkSection.js";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);
export default function Components(props) {
  const { width } = useWindowDimensions();

  const classes = useStyles();
  const { ...rest } = props;
  useEffect(() => {
    console.log("hello")
    if(localStorage.getItem("role")=="Panel"){
      var email=localStorage.getItem("patient_id")
    var password=localStorage.getItem("id")
    var role =localStorage.getItem("role")
    var log='ID'
    // fetch('http://reports.mpl-labs.pk:8443/4DACTION/WebLogin',{
    fetch('https://reports.mpl-labs.pk:8443/4DACTION/WebLogin',{
  
    method: 'POST',
      'Content-Type':"application/json",
      body:'vUID=' + email + '&vPW=' + password + '&vRole=' + role + '&vLoginType=' + log + "&vRandom=" + 21211
    }).then((res)=>res.text()).then( async(resss)=>{
      console.log("LabReports Check auto logout"+resss)
     
      var res=JSON.parse(resss)
      if(res.Result){
        console.log("Resulte true Login Cridential "+res.Result)
      }
      else{
        console.log("Resulte false Login Cridential "+res.Result)
        alert("Session Expired Please Login Again ..... !")
        setTimeout(() => {
          localStorage.clear()
      window.location.href = "https://mpl-labs.pk/Login"
      // window.location.href = "http://localhost:3000/Login"

          
        }, 500);
      }
     

    
    })
    }
//  end 06-june-2023

  },[])
  
  return (
    <div style={{backgroundColor:"#196c90"}}>
      <Header
        brand="Metropole Laboratories Private Limited"
        //rightLink me headerLink pass kr rhe hn jo show ho rhy hn Home,Services,About Us etc on the base of login patient ,admin etc 
        rightLinks={<HeaderLinks />}
        fixed
        // 21-june -2023
        // color="transparent"
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "black"
        }}
        {...rest}
      />
     {/* <div style={{marginTop:'8em'}}> */}
      {/* when new image recieved 26-aug-2023 */}
     <div style={{width:'100%',marginTop:width<500?"5.5em":width<950?"6.5em":width<1200?"5.5em":width<1290?"6.5em":'7.7em',}}>
     
     <SectionCarousel />
     {/* <SectionNavbars></SectionNavbars> */}
     
       {/* </div> */}
       </div>
     
    
   
{/* these classes.main are call from import styles from "assets/jss/material-kit-react/views/components.js"; */}
      <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?5:5}}>
          <ProductSection />
          {/* Our Lab service */}
          <TeamSection />
          {/* Our Radiology Service  */}
          <TeamSectionNew></TeamSectionNew>
       {/* thses are Free HomSampling  section that arae display in screen*/}
          <FreeHS_Msg />
          {/* <SlickCarousel/> */}
      </div>
      <Footer />
    </div>
  );
}
