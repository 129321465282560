import React ,{useEffect} from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import COVID19_PCR from '../COVID19_PCR/Covid19_PCR.js'
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import FreeHS_Msg from '../Components/FreeHS_Msg'
import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import molecule_1 from 'assets/img/animations/virus (13).png';
import useWindowDimensions from "../../useWindowDimensions";


import molecule_6 from 'assets/img/animations/virus (6).png';

import molecule_7 from 'assets/img/animations/virus (9).png';
import team1 from "assets/img_stock/New2023/Department/2.jpg";

import report from 'assets/img/lab_test.png';


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Molecular(props) {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  const { ...rest } = props;
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },[])
  return (
    <div style={{backgroundColor:"#196c90"}}>
      <Header
        // color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          // when scroll up to 400px color change into white otherwise its transparent that are define in 6 line above
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      {/*style={{marginTop:100}}  */}
      <Parallax filter image={require("assets/img_stock/New2023/Department/4new.jpg")} style={{marginTop:width<500?90:100}}
>
        <div className={classes.container}>
          
          <GridContainer>
            
            <GridItem>
              {/* className="thses classes are written in assest=>scss=>core=>mics.scss " */}
            {/* <img src={molecule_1} className="funfact-two__virus" alt="" style={{
  width: '5em',
  height: 'auto', right:'30em'}}/>
       <img src={molecule_6} className="funfact-two__virus__5" alt="" style={{  width: '5em',
  height: 'auto',}}/>
       <img src={molecule_7} className="funfact-two__virus__7" alt="" style={{ width: '5em',
  height: 'auto',top:'10em'}}/>    */}
      
      {width<500?
              <h2 className={classes.title} style={{marginTop:-40}}>Molecular Pathology & Genetics</h2>
:
width<950?
  <h3 className={classes.title} style={{marginTop:-40}}>Molecular Pathology & Genetics</h3>
:
<h1 className={classes.title} style={{marginTop:-40}}>Molecular Pathology & Genetics</h1>
}
     
           
             
              {/* <br /> */}
              <p   style={{color:'white',textAlign:"justify"}}>The Molecular Pathology & Genetics section specializes in genetic and molecular testing for
different diseases. Highly trained molecular biologists, state-of-the-art equipment, and strict
quality control measures ensure accurate and reliable results. Our BSL-2 COVID Lab is approved
lab by NIH & IHRA. MPL has also collaborated with different international labs to out source rare
genetic tests. Additionally, our clinical geneticist, Dr. Hani Akbar, offers counseling for genetic
disorders. 
          
             
             </p>
           
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      {/* <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?-120:-60}}> */}
      <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?-150:-200}}>

        <div className={classes.container} style={{paddingTop:"1em", paddingBottom:"1em"}} >
        
            {/* <br /> */}
  <GridContainer>
  <GridItem xs={12} sm={12} md={12} lg={12}  style={{display:"flex",justifyContent:"center",alignItems:"center"}}>

             {/* <h3 className={classes.title} style={{color:'black',marginTop:"0px"}}>Molecular Pathology & Genetics</h3> */}
             {/* <p   style={{color:'black'}}>The Molecular Pathology & Genetics section specializes in genetic and molecular testing for
different diseases. Highly trained molecular biologists, state-of-the-art equipment, and strict
quality control measures ensure accurate and reliable results. Our BSL-2 COVID Lab is approved
lab by NIH & IHRA. MPL has also collaborated with different international labs to out source rare
genetic tests. Additionally, our clinical geneticist, Dr. Hani Akbar, offers counseling for genetic
disorders.
          
             
             </p> */}
             <h3 className={classes.title} style={{color:'black',textAlign:"center",marginTop:"0px"}}>Technologies In Use</h3>

          </GridItem>
          {/* <GridItem xs={12} sm={12} md={6} lg={6}>
          <img
                src={require("assets/img_stock/bio_chem_1.JPG")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
          </GridItem> */}
      </GridContainer>
      <br />
      <GridContainer>
        {/* 1st */}
        <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",alignItem:"center",flexDirection:"column"}}>
         <img
                src={require("assets/img_stock/New2023/MachineImages/Molecular/6.jpg")}
                style={{ 
                maxWidth: "100%",
                // maxHeight:"50%",
                alignSelf:"center",
                height: "auto",
                // borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
                 <p   style={{color:'black',marginTop:10,textAlign:"center"}}>QIAsymphony Sample Purification System by QIAGEN, Germany</p>
          </GridItem>
            {/* 2nd */}
            <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",alignItem:"center",flexDirection:"column"}}>
         <img
                src={require("assets/img_stock/New2023/MachineImages/Molecular/2.jpg")}
                style={{ 
                maxWidth: "100%",
                // maxHeight:"50%",
                alignSelf:"center",
                height: "auto",
                // borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
                 <p   style={{color:'black',marginTop:10,textAlign:"center"}}>Super Extractor 32, by
Systaaq USA</p>
          </GridItem>
           {/* 3rd */}
 <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",alignItem:"center",flexDirection:"column"}}>

<img
       src={require("assets/img_stock/New2023/MachineImages/Molecular/5.jpg")}
       style={{ 
       maxWidth: "100%",
       height: "auto",
       // maxHeight:"50%",

       borderRadius: "calc(.25rem - 1px)",
       boxShadow:
       "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
   
   }}
     />
        <p   style={{color:'black',marginTop:10,textAlign:"center"}}>Rotor-Gene Q 5Plex Amplification System by QIAGEN, GERMANY</p>
 </GridItem>
 {/* 4th */}
 <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",alignItem:"center",flexDirection:"column"}}>

<img
       src={require("assets/img_stock/New2023/MachineImages/Molecular/1.jpg")}
       style={{ 
       maxWidth: "100%",
       height: "auto",
       // maxHeight:"50%",

       borderRadius: "calc(.25rem - 1px)",
       boxShadow:
       "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
   
   }}
     />
        <p   style={{color:'black',marginTop:10,textAlign:"center"}}>AB QuantGene, Automated
PCR System by Systaaq USA</p>
 </GridItem>
         
        {/* 5th */}
      <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",alignItem:"center",flexDirection:"column"}}>

<img
       src={require("assets/img_stock/New2023/MachineImages/Molecular/3.jpg")}
       style={{ 
       maxWidth: "100%",
       // maxHeight:"50%",
       height: "auto",
       // borderRadius: "calc(.25rem - 1px)",
       boxShadow:
       "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
   
   }}
     />
        <p   style={{color:'black',marginTop:10,textAlign:"center"}}>Gene Expert, Automated Molecular
System by Cepheid USA</p>
 </GridItem>

 
          {/* 4th */}
          <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",alignItem:"center",flexDirection:"column"}}>

<img
       src={require("assets/img_stock/New2023/MachineImages/Molecular/4.jpg")}
       style={{ 
       maxWidth: "100%",
       height: "auto",
       // maxHeight:"50%",

       borderRadius: "calc(.25rem - 1px)",
       boxShadow:
       "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
   
   }}
     />
        <p   style={{color:'black',marginTop:10,textAlign:"center"}}>Automated LAMP System by
Hibergene Ireland</p>
 </GridItem>
 
        
        
       
          {/* <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",alignItem:"center",flexDirection:"column"}}>
         <img
                src={require("assets/img_stock/New2023/MachineImages/ClinicalChemistry/5.png")}
                style={{ 
                maxWidth: "100%",
                maxHeight:"50%",
                alignSelf:"center",
                // height: "auto",
                // borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
                 <p   style={{color:'black',marginTop:10}}>Blue Diver, Automated Microarray
Immunoblotting system by D-Tek Belgium</p>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",alignItem:"center",flexDirection:"column"}}>

         <img
                src={require("assets/img_stock/New2023/MachineImages/ClinicalChemistry/6.png")}
                style={{ 
                maxWidth: "100%",
                maxHeight:"50%",
                // height: "auto",
                // borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
                 <p   style={{color:'black',marginTop:10}}>Alegria, Automated Immunoassay
system by Orgentec Germany</p>
          </GridItem> */}
          
          
      </GridContainer>
      {/* free home smapling msg that are display in below */}
            {/* <FreeHS_Msg /> */}
          {/* <ProductSection />
          <TeamSection />
          <WorkSection /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
