import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import Close from "@material-ui/icons/Close";

import IconButton from "@material-ui/core/IconButton";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";

import TextField from '@material-ui/core/TextField';
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Slide from "@material-ui/core/Slide";

import url from '../../link'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogActions from "@material-ui/core/DialogActions";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/building.jpeg";

const useStyles = makeStyles(styles);
  
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export default function AirBlue(props) {

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  useEffect(()=>{
    if(localStorage.getItem('flydubai')=='true' || localStorage.getItem('patient')!=null || localStorage.getItem('emirates')=='true' || localStorage.getItem('airblue')=='true'){
        window.location.href="https://backup.mpl-labs.pk/"
       }
  },[])
  const [classicModal, setClassicModal] = React.useState(false);
  const [email, setEmail]= React.useState('')
  const [password, setPassword]= React.useState('')
  const classes = useStyles();
  const [role, setRole]= React.useState('Patient')
  const [ServerResponse, setServerResponse]= React.useState('')
  const { ...rest } = props;
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "center bottom"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader  className={classes.cardHeader} style={{backgroundColor:'black'}}>
                    <h4 style={{color:'white'}}>Onix Enterprises</h4>
                    
                  </CardHeader>
                  <CardBody>
                
                  <TextField required id="" block
                  fullWidth={true}
                  label="ID"
                  value={email}
                  onChange={(e)=>{
                    setEmail(e.target.value)
                  }} 
                  
                  />
                  
                  <br />
                  <br />
                    <TextField required id="" block
                  fullWidth={true}
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(e)=>{
                    setPassword(e.target.value)
                  }} 
                  
                  />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button color="#196c90" size="lg" style={{backgroundColor:'black'}}
                     onClick={() => {
                       if(email.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Kindly Enter Email or ID!!')
                        return
                    
                       }
                       if(password.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Kindly Enter Password !!')
                        return
                    
                       }
                       var log=''
                      fetch('https://reports.mpl-labs.pk:8443/4DACTION/WebLoginOnix?vUID='+email+'&vPW='+password).then((res)=>res.text()).then((res)=>{
                               if(res=='Successful'){
                                setServerResponse('Login Successfully!')
                                setClassicModal(true)
                                localStorage.setItem('onix',true)
                                setTimeout(()=>{
                                    window.location.href=url+"/OnixEnterprises_COVID19_PCR"
                                  },1000)
                               }else{
                                setServerResponse('Invalid Credentials !')
                                setClassicModal(true)
                               }
                      })
                    }}
                    >
                      Login
                    </Button>
                    
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={4}>

            <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}
                  open={classicModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h4 className={classes.modalTitle}><b>Onix Enterprise Login</b></h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>
                   {ServerResponse}
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => setClassicModal(false)}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
          </GridContainer>
         
        </div>
       
      </div>
    </div>
  );
}
