import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import HelpIcon from '@material-ui/icons/Help';
import { isMobile } from 'react-device-detect';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EditIcon from '@material-ui/icons/Edit';

import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';

import Invoice from './PDF/Invoice.js'








import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';









const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}


function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
function to24Hours(d) {
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
  return h + ":" + m + ":" + s;
}
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(seconds) {

  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return addZero(hours) + ':' + addZero(minutes);
}

export default function Lab_Tests() {
  const classes = useStyles();
  var date = new Date()
  var temp_role = localStorage.getItem('role')
  date.setDate(date.getDate() - 2);

  const [selected, setSelected] = React.useState({
    reports: []
  })

  const [OpenCovidTemplate, setCovidTemplate] = React.useState(false)

  const [OpenOfficialCovidTemplate, setOfficialCovidTemplate] = React.useState(false)

  const [patient_invoice, setPatientInvoice] = React.useState(false)


  const [simple_invoice, setSimpleInvoice] = React.useState(false)

  const [sample_invoice, setSampleInvoice] = React.useState(false)

  const [from, setFrom] = React.useState(date)
  const [to, setTo] = React.useState(new Date())
  const [width, height] = [window.innerWidth, window.outerWidth];
  const [panel, setPanel] = React.useState('All')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  //for Edit Patient 11-jan-2023
  


  const [selectedCovid, setSelectedCovid] = React.useState({
    reports: []
  })
  const [selectedOfficialCovidReport, setSelectedOfficialCovidReport] = React.useState({
    reports: []
  })


  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(true)

  const [covidButton, setCovidButton] = React.useState(false)

  const [panelList, setpanelList] = React.useState([])
  const [laborder, setLabOrder] = React.useState(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branches, setBranches] = React.useState([])
  const [branch, setBranch] = React.useState("")
  const [selectBranch, setSelectBranch] = React.useState([])
  const [selectOrders, setSelectedOrders] = React.useState([])
  const [state, setState] = React.useState({
    status: 'All',
  });

  //// --------------------------------------
  const [selectedReceipt,setSelectedReceipt]=React.useState("")
  const [selectReceiptOpen,setSelectReceiptOpen]=React.useState(false)

  var svgString2Image = (svgString, width, height, format, callback) => {

  if(svgString.trim()==""){
    callback('')
    return ""
  }

    var svgStringFormat = window.atob(svgString)
    var temp = svgStringFormat.split(`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`)
    // set default for format parameter
    format = format ? format : 'png';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp[1])));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    var context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () { // async (happens later)
      // clear canvas
      context.clearRect(0, 0, width, height);
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, width, height);
      // snapshot canvas as png
      var pngData = canvas.toDataURL('image/' + format);
      // pass png data URL to callbac
      callback(pngData)
    }; // end async
    image.src = svgData

  }

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const numberWithCommas= (x)=> {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(()=>{
    
    var temp = {
      Invoice_No:localStorage.getItem('invoiceNoForInvoiceshow')+""
    }
    fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
      method: 'POST',
      'Content-Type': "application/json",
      body: JSON.stringify(temp)
    }).then((res) => res.json()).then(async (record) => {
      console.log("Invoice Record when click on Printed Invoice button response of api :",record)
      svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
        svgString2Image(record[0].FBR_QR_Code, 800, 600, 'png', async (FBR_QR_Code) => {
          console.log('Test')
        var flight_date = new Date(record[0].Flight_Date).ddmmyyy()
        var print_date = new Date().ddmmyyy()
        var invoice_date = new Date(record[0].Invoice_Date).ddmmyyy()
        var orders = JSON.parse(record[0].Array_Test_Code)

        var counter = -1

        var groupBox={}

        var TableRows=[]

        var grouping=JSON.parse(record[0].ArrGroupingTitle)

        var fee=0
        var disc=0
        var net_value=0

        grouping.map((title,index)=>{
          if(title.trim()==""){
            return
           }
           fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
           disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
           net_value=JSON.parse(record[0].Array_Payable)[index] + net_value

          groupBox[title]={
            sn: index+1,
            test_title: ( groupBox[title] ==undefined ? title + "\n" : groupBox[title].test_title ) + "\n - "+ JSON.parse(record[0].Array_Test_Name)[index]  ,
            reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
            fee: numberWithCommas((fee).toFixed(2)),
            disc: numberWithCommas((disc).toFixed(2)),
            net_value: numberWithCommas((net_value).toFixed(2)),
            branch: record[0].Branch_Name,
            User_Name: record[0].user_name,
            cnic: record[0].CNIC,
            name: record[0].Patient_Name,
            passport: record[0].Passport_No,
                 
          }
        })

        Object.keys(groupBox).map((key)=>{
          TableRows.push(groupBox[key])

        })
        console.log(groupBox)

        var up_order = await orders.map((item,index) => {
          
          if(JSON.parse(record[0].ArrGroupingTitle)[index].trim()!=""){
          
            return
           }

          fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
          disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
          net_value=JSON.parse(record[0].Array_Payable)[index] + net_value

          counter++
          TableRows.push({
            sn: counter + 1,
            test_title: JSON.parse(record[0].Array_Test_Name)[index],
            reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
            fee: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index]).toFixed(2)),
            disc: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index] - JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
            net_value: numberWithCommas((JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
            branch: record[0].Branch_Name,
            User_Name: record[0].user_name,
            cnic: record[0].CNIC,
            name: record[0].Patient_Name,
            passport: record[0].Passport_No,
            //18-jan-2023 show tret detail in row parameter tests_line_details
            // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
            tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")
          
          })
          return ""

        })

        Promise.all(up_order).then((values) => {
          var obj1 = {
            branch: record[0].Branch_Name,
            barcode: barcode,
            name: record[0].Patient_Name,
            age_gender: record[0].Age_Gender,
            password: record[0].Password,
            patient_id: record[0].Login_Name,
            Base64_ImagePassport:"data:image/png;base64,"+record[0].Base64_ImagePassport,
             Base64_PicturePatient:"data:image/png;base64,"+record[0].Base64_PicturePatient,
            print_time: formatAMPM(new Date()),
            print_date: print_date,
            Ref_By: record[0].Ref_By,
            Ref_No: record[0].Ref_No,
            // FBR
            pos:FBR_QR_Code,
            pos_id:record[0].FBR_ID,

            BranchAddress:record[0].BranchAddress,
            BranchPhone:record[0].BranchPhone,
            BranchTiming:record[0].BranchTiming,
            BranchTitle:record[0].BranchTitle,
            BranchExcLetterhead:record[0].BranchExcLetterhead,


            Invoice_Remarks: record[0].Invoice_Remarks,
            Patient_Comments: record[0].Patient_Comments,
            invoice_time: msToTime(record[0].Invoice_Time),
            Entitlement_Name: record[0].Entitlement_Name,
            invoice_date: invoice_date,
            Discount_Total: numberWithCommas((disc).toFixed(2)),
            Invoice_Value: numberWithCommas((fee).toFixed(2)),
            User_Name: record[0].user_name,
            cnic: record[0].CNIC,
            Ticket_No: record[0].Ticket_No,
            invoice_id: record[0].Invoice_ID,
            contact: record[0].Mobile_No,
            Panel_Name: record[0].Panel_Name,
            passport_no: record[0].Passport_No,
            flight_date: flight_date,
            flight_no: record[0].Flight_No,
            flight_time: msToTime(record[0].Flight_Time),
            toAddress:record[0].To_Address,
            reports: TableRows,
            Invoice_Payable: numberWithCommas(record[0].Invoice_Payable.toFixed(2)),
            Invoice_Received: numberWithCommas(record[0].Invoice_Received.toFixed(2)),
            Invoice_Balance: numberWithCommas(record[0].Invoice_Balance.toFixed(2)),
            // cnic: "",

            // for new MR panel 3-JAN-2023
            if_panelMR_cnic_father:record[0].if_panelMR_cnic_father,
          //  if_panelMR_cnic_father:true,
// cnic:"37405-1287363-9", Already oper aa rha es lye es cond ko bhej dya hai
father_husband:record[0].if_panelMR_cnic_father==true && record[0].father_husband!=""?record[0].father_husband:"null"
//  father_husband:"khalid mehmood"


          }
          setSelected(obj1)

          setTimeout(() => {
            setPatientInvoice(true)

          }, 500);
        })

      })
    })


    })

  },[])


  Date.prototype.hhmm = function () {
    var mm = this.getMinutes();
    var hh = this.getHours();

    return [
      (hh > 9 ? '' : '0') + hh,
      (mm > 9 ? '' : '0') + mm
    ].join(':');
  };

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  return (
    <>
      <Paper className={classes.root}>
      {/* <ReactLoading></ReactLoading> */}

      </Paper>
      {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={patient_invoice}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setPatientInvoice(false)
          setSelected({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Printed Invoice</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selected.name != undefined ?
              <center> <Fragment key={"sqq"}>
                <PDFViewer width="1000" height="1000" className="app" >
                  <Invoice invoice={selected} />
                </PDFViewer>
              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setPatientInvoice(false)
              window.close();

              setSelected({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      
  {/* //for Edit Patient 11-jan-2023 */}

      

    </>
  );
}
