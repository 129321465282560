import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";

import Person from "@material-ui/icons/Person";
import PhoneAndroid from "@material-ui/icons/PhoneAndroid"
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Datetime from "react-datetime";
import Button from "components/CustomButtons/Button.js";

import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import url from '../../link'

import image from "assets/img/bg7.jpg";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FormatAlignCenter } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export default function RegisterPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [classicModal, setClassicModal] = React.useState(false);
  const [firstName,setFirstName]= React.useState('')
  const [lastName,setLastName]= React.useState('')
  const [title,setTitle]= React.useState('Select')
  const [gender,setGender]= React.useState('Select')
  const [email,setEmail]= React.useState('')
  const [age,setAge]= React.useState(0)
  const [MobileNumber,setMobileNumber]= React.useState('')
  const [network , setNetwork]= React.useState('Select')
  
  const [ServerResponse, setServerResponse]=React.useState('')
  
  
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader  className={classes.cardHeader} style={{backgroundColor:'#196c90'}}>
                    <h4 style={{color:'white'}}>Patient Registraion</h4>
                    
                  </CardHeader>
                  <CardBody>
                  <TextField required id="" block
                  fullWidth={true}
                  label="First Name"
                  value={firstName}
                  onChange={(e)=>{
                    setFirstName(e.target.value)
                  }} 
                  
                  />
      <br />
      <br />
                     <TextField required id="" block
                  fullWidth={true}
                  label="Last Name"
                  value={lastName}
                  onChange={(e)=>{
                    setLastName(e.target.value)
                  }} 
                  
                  />
      <br />
      <br />
                  <FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Title</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={title}
          onChange={(e)=>{
       setTitle(e.target.value)
          }}
        >
          <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
         
          <MenuItem value={"Mr."}>Mr.</MenuItem>
          
          <MenuItem value={"Miss"}>Miss</MenuItem>
          <MenuItem value={"Mrs."}>Mrs.</MenuItem>
          <MenuItem value={"Baby"}>Baby</MenuItem>
          <MenuItem value={"Master"}>Master</MenuItem>
          <MenuItem value={"Sir"}>Sir</MenuItem>
          <MenuItem value={"Madam"}>Madam</MenuItem>
          <MenuItem value={"C/O"}>C/O</MenuItem>
          <MenuItem value={"B/O"}>B/O</MenuItem>
          <MenuItem value={"W/O"}>W/O</MenuItem>
          <MenuItem value={"D/O"}>D/O</MenuItem>
          <MenuItem value={"S/O"}>S/O</MenuItem>
          <MenuItem value={"H/O"}>H/O</MenuItem>
          <MenuItem value={"M/O"}>M/O</MenuItem>
          <MenuItem value={"Dr.-"}>Dr.</MenuItem>

          
        </Select>
      </FormControl>
      <br />
      <br />
      <FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Gender</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={gender}
          onChange={(e)=>{
       setGender(e.target.value)
          }}
        >
        <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
          <MenuItem value={"Male"}>Male</MenuItem>
          <MenuItem value={"Female"}>Female</MenuItem>
        </Select>
      </FormControl>
     <br />
     <br />
      <TextField required id="" block
                  fullWidth={true}
                  label="Email"
                  value={email}
                  onChange={(e)=>{
                    setEmail(e.target.value)
                  }} 
                  
                  />
                    <br />
     <br />
                    <TextField required id="" block
                  fullWidth={true}
                  label="Age"
                  value={age}
                  type="number"
                  onChange={(e)=>{
                    var age=e.target.value
                    setAge(age)
                   } }
                  
                  />
                     <br />
                    <FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Mobile Network</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={network}
          onChange={(e)=>{
            setNetwork(e.target.value)
          }}
        >
           <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
          <MenuItem value={"Ufone"}>Ufone</MenuItem>
          <MenuItem value={"Zong"}>Zong</MenuItem>
          <MenuItem value={"Mobilink"}>Mobilink</MenuItem>
          <MenuItem value={"Telenor"}>Telenor</MenuItem>
          <MenuItem value={"Warid"}>Warid</MenuItem>
        </Select>
      </FormControl>
      <br />
      <br />
      <TextField required id="" block
                  fullWidth={true}
                  type="number"
                  label="Mobile Number"
                  value={MobileNumber}
                  onChange={(e)=>{
                    setMobileNumber(e.target.value)
                  }} 
                  
                  />

                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button color="#196c90" size="lg" style={{backgroundColor:'#196c90'}}
                     onClick={()=>{
                       var number=MobileNumber+""
                       
                       
                      if(firstName.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Kindly Enter First Name !!')
                        return
                       }
                       if(lastName.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Kindly Enter Last Name !!')
                        return
                       }
                       if(gender=='Select'){
                        setClassicModal(true)
                        setServerResponse('Kindly Select Gender !!')
                        return
                       }
                       if(number.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Kindly Enter Mobile Number !!')
                        return
                       }
                       if(network=='Select'){
                        setClassicModal(true)
                        setServerResponse('Kindly Select Network!!')
                        return
                       }
                       if(email.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Kindly Enter Email Address !!')
                        return
                       }
                       if(title=='Select'){
                        setClassicModal(true)
                        setServerResponse('Kindly Select Title !!')
                        return
                       }
                       if(age<=0 || age==''){
                        setClassicModal(true)
                        setServerResponse('Invalid Age!!')
                        return
                       }
                       var dateOfBirth = new Date();
                dateOfBirth.setFullYear(dateOfBirth.getFullYear() - age);
                var dd = dateOfBirth.getDate();
                            var mm = dateOfBirth.getMonth() + 1; //January is 0!
                
                            var yyyy = dateOfBirth.getFullYear();
                            if (dd < 10) {
                                dd = '0' + dd;
                            }
                            if (mm < 10) {
                                mm = '0' + mm;
                            }
                var birth_date = dd + '/' + mm + '/' + yyyy;
                       fetch('https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_RegisterPatient_WS?first_name='+firstName+'&last_name='+lastName+'&email='+email+'&birth_date='+birth_date+'&network='+network+'&gender='+gender+'&to_address='+title+'&M_Status=Single&mobile_number='+number).then((response) => {
                          return response.text();
                       }).then((res)=>{
                         console.log(res) 
                        if(res=="Successful"){
                          setClassicModal(true)
                          setServerResponse('Patient Account Created, Kindly Check Email for Credentails!!')
                          setTimeout(()=>{
                            window.location.reload()
                          },3500)
                          setTimeout(() => {
                            window.location.href=url+"/Login"
                          }, 3000);
                        return
                        }
                        else if(res=="Failure"){
                          setClassicModal(true)
                          setServerResponse('Sorry , Registration Failed!!')
                          return
                      
                         }
                        else{
                          setClassicModal(true)
                          setServerResponse('Patient Already Exists!!!')
                          return
                      
                        }
                      })
                       
                       
                     }}
                    >
                      Register
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={4}>

            <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}
                  open={classicModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h4 className={classes.modalTitle}><b>Patient Registration</b></h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>
                      {ServerResponse}
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => setClassicModal(false)}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
          </GridContainer>

        </div>
       
      </div>
    </div>
  );
}
