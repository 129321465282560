import React, {Fragment} from 'react';
import {Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTitle from './InvoiceTitle'
import Comments from './Comments'

const styles = StyleSheet.create({
    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 10,
      borderWidth: 1,
      fontFamily: "Calibri Regular",
      borderColor: 'black'
    },
    Comments:{
      color: 'black',
      fontSize: 9,
      fontFamily:'Times-Bold',
      textAlign: 'center',
      textTransform: 'uppercase',
      textDecoration:'underline'
  },
  });
const InvoiceTableRow = ({items}) => {
    var counter=0

    const rows = items.map(item =>{
      var marginTopCP=item.prev1.length>4 ? 15 :1
        
            counter++
    
            return (<View>
               {
            (counter==15 && marginTopCP==15 )
            ?
            <InvoiceTableHeader />
            
            :null
          }
        <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        fontSize:7,
        marginTop:1,
        borderWidth:1
        }} key={item.test_name}>
         
             <Text style={{ 

        width: '25%',
        textAlign: 'left',
        paddingTop:5,
        paddingLeft: 8,
        fontFamily:"Calibri Regular",
        height:'100%',
        textAlign:'left',
        fontSize:8
        }}>{item.test_name}</Text>
         <Text style={{ 
                 
        width: '20%',
        textAlign: 'center',
        borderLeftColor: 'black',
        borderLeftWidth: 1,
        paddingTop:5,
        fontWeight:'',
        fontSize:9,
        height:'100%',
        paddingLeft: 8,
        fontFamily:"Helvetica-Bold",
        
        }}>{item.result}</Text>
         <Text style={{ 
                 
        width: '15%',
        height:'100%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 1,
        paddingTop:5,
        fontFamily:"Calibri Regular",
        fontSize:9,
        paddingLeft: 8,
    
        
        
        }}>{item.ref_range}</Text>
        <Text style={{ 
                 height:'100%',
                 width: '10%',
                 textAlign: 'left',
                 borderLeftColor: 'black',
                 borderLeftWidth: 1,
                 paddingTop:5,
                 fontFamily:"Calibri Regular",
                 fontSize:9,
                 paddingLeft: 8,
                 
                 
                 
                 }}>{item.unit}</Text>

         <Text style={{ 
                 
        width: '15%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 1,
        paddingTop:5,
        height:'100%',
        fontFamily:"Calibri Regular",
        fontSize:9,
        paddingLeft: 5,
        
        
        }}>{item.prev1}</Text>
            <Text style={{ 
                 
        width: '15%',
        height:'100%',
        textAlign: 'left',
        borderLeftWidth:1,
        paddingTop:5,
        fontSize:9,
        fontFamily:"Calibri Regular",
        textOverflow: 'auto',
        paddingLeft: 8
        
        }}>{item.prev2}</Text>
           
        </View>

        </View>
    )
    })

    return (<Fragment>{rows}</Fragment> )
};
  
  export default InvoiceTableRow