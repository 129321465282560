import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text, Font } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import logo from '../mplLogoNew30-07-24.jpeg'
import footer from '../MPI New Reproting Ietterhead 30-07-2024 Footer.jpeg'

import CovidPDF from '../Covid_PDF/CovidTemplate'
import InvoiceTitleNewGroup from './InvoiceTitleNewGroup';
import InvoiceTitleNewGroupLine from './InvoiceTitleNewGroupLine';


Font.register({ family: 'Calibri Regular', src: require('../../../fonts/Calibri Regular.ttf'), });

const styles = StyleSheet.create({
    page: {
        paddingTop: 130,
        paddingBottom: 100,
        fontSize: 8,
        fontFamily: "Helvetica",
        paddingLeft: 20,
        paddingRight: 20,
        lineHeight: 1.5
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },
    qrcode: {
        width: 60,
        height: 60
    },
    logo: {
        width: 180,
        height: 100
    },
    barcode: {
        width: 130,
        height: 30,
    }
});

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const Report = ({ invoice }) => {
    const [numPages, setNumPages] = React.useState(null);
    const [totalPages, setTotalPages] = React.useState(null)
    // for show GroupTitle we can use title otherWise it will written as invoice.list
    var title = Object.keys(invoice.Microbiology).map((key) => {
        return key
    })

    return (<Page
        style={{
            paddingTop: 170,
        // {/* 12-aug-2023 paddingbottom 150 thi aur niche footer of thora oper kiya tha */}

            paddingBottom: 130,
            fontSize: 8,
            paddingLeft: 40,
            paddingRight: 20,
            lineHeight: 1.5
        }}
        size="A4"

        wrap={true}
    >
          <Text style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 5,
                            width:"100%",
                            marginTop:10,
                            marginLeft:34,
                            textAlign:"right",
                            marginBottom: 1,
                            position:"absolute"

                        }}>{"DOC No: MPL-GEN-F026 | Issue No: 01 | Issue Date: 04-01-2021"}</Text>
                          {/* below commint and patientimge!==null for show patient image in pdf */}
              <View fixed style={{
                            //fixed is used for display in all pages
                            width: '100%', display: 'flex', flexDirection: 'row', top: 0, position: 'absolute',
                            paddingLeft: 20,
                            alignItems: "center",
                            fontFamily: "Helvetica",
                            paddingRight: 20,
                            paddingTop:invoice.PatientImage !==null? 15:10,
                            // paddingTop:10,
                           // backgroundColor:"red"
                        }} >
                            {/* <Image style={[styles.logo,{height:invoice.PatientImage !==null?90: 100,width:invoice.PatientImage !==null?150:200}]} src={logo} /> */}
                            <Image style={[styles.logo,{height: 100,width:178,marginTop:invoice.PatientImage !==null?-20:0}]} src={logo} />
                            
{/* <View style={{ marginLeft:invoice.PatientImage !==null?10: 25,marginTop:10, flexDirection: "column", alignItems: "center" }}> */}
                            <View style={{ marginLeft: 51,marginTop:invoice.PatientImage !==null?0:10, flexDirection: "column", alignItems: "center" ,
                            // backgroundColor:"green"
                            }}>
                             
                                <Image style={[styles.barcode,{height:invoice.PatientImage !==null?25:30}]} src={invoice.barcode} />
                            
                                <View style={{
                                    margin: 'auto', 
                                    // width: 80,
                                    width:invoice.PatientImage !==null?75:80, 
                                    backgroundColor: '#F2F0F0',
                                    borderWidth: 1,
                                    borderColor: 'black',
                                    // marginTop: 20
                                    marginTop:invoice.PatientImage !==null?8.5:20
                                }}>
                                    <Text style={{ paddingTop: '3em', paddingLeft: '5em', paddingRight: '5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize:invoice.PatientImage !==null? 7.5:8.5,
                                // fontSize:8.5
                                }}>MPL ID : {invoice.invoice_id}</Text>
                                </View>
                                {invoice.PatientImage !==null  ? <View style={{height:65,width:75,marginTop:8.5,}}>
                        <Image height={800} width={200}  src={invoice.PatientImage} />

                            </View>:<></>}

                            </View>
                            
                          
                       
                            {/* <View style={{ marginLeft:invoice.PatientImage !==null?   10: 27.5, marginTop: 5 }}  > */}
                            <View style={{ marginLeft: 32, marginTop:invoice.PatientImage !==null? -10:5 }}  >
        {/* <View fixed style={{
            width: '100%', display: 'flex', flexDirection: 'row', top: 0, position: 'absolute',
            paddingLeft: 20,
            alignItems: "center",
            fontFamily: "Helvetica",
            paddingRight: 20,
            paddingTop: 10
        }} >
            <Image style={styles.logo} src={logo} />

            <View style={{ marginLeft: 50, flexDirection: "column", alignItems: "center" }}>
                <Image style={styles.barcode} src={invoice.barcode} />
                <View style={{
                    margin: 'auto', width: 80, backgroundColor: '#F2F0F0', borderWidth: 1,
                    borderColor: 'black',
                    marginTop: 20
                }}>
                    <Text style={{ paddingTop: '3em', paddingLeft: '5em', paddingRight: '5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 8.5 }}>MPL ID : {invoice.invoice_id}</Text>
                </View>


            </View>
            <View style={{ marginLeft: 30, marginTop: 5 }}  > */}


                <Text style={{
                    marginLeft: '5em',
                    width: "170em",
                    fontFamily: 'Helvetica-Bold',
                    fontSize: 9,
                    marginTop: 5,
                }}>{invoice.name}</Text>
                 {/*  for new MR panel 3-JAN-2023 */}
                 {
                                    invoice.father_husband == "null"
                                        ?
                                        <Text></Text>
                                        :
                                        <View style={{
                                            marginLeft: '5em',
                                            paddingTop: '5em',
                                            fontSize: 7,
                                            flexDirection: "row",
                                            width: "170em",
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            borderBottomColor: 'black',
                                            borderBottomWidth: 0.3,

                                        }}>
                                            <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>Father / Husband</Text>
                                            <Text style={{ width: "10%" }}>:</Text>
                                            <Text style={{ width: "45%" }}>{invoice.father_husband}</Text>
                                        </View>
                                }
                                {
                                    invoice.cnic == "null"
                                        ?
                                        <Text></Text>
                                        :
                                        <View style={{
                                            marginLeft: '5em',
                                            paddingTop: '5em',
                                            fontSize: 7,
                                            flexDirection: "row",
                                            width: "170em",
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            borderBottomColor: 'black',
                                            borderBottomWidth: 0.3,

                                        }}>
                                            <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>CNIC</Text>
                                            <Text style={{ width: "10%" }}>:</Text>
                                            <Text style={{ width: "45%" }}>{invoice.cnic}</Text>
                                        </View>
                                }
                <View style={{
                    marginLeft: '5em',
                    paddingTop: '5em',
                    fontSize: 7,
                    flexDirection: "row",
                    width: "170em",
                    paddingTop: 2,
                    paddingBottom: 2,
                    borderTopWidth: 0.3,
                    borderBottomColor: 'black',
                    borderBottomWidth: 0.3,

                }}>
                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>{invoice.age_gender.split('|')[0]}</Text>
                                                <Text style={{ width: "10%" }}>:</Text>
                                                {/* {invoice.invoice_id==2101409 || invoice.invoice_id==2701404 ? */}
                    {/* {invoice.invoice_id==2101409 || invoice.invoice_id==2701404 ||invoice.invoice_id==1901385||invoice.invoice_id==5801467 || invoice.invoice_id== "0401479" ||invoice.invoice_id==9701419||invoice.invoice_id==5801466 || invoice.invoice_id==5901519 || invoice.invoice_id==7101439 ||invoice.invoice_id==4401512||invoice.invoice_id==9201456 || invoice.invoice_id== 9201457  ? */}
                      {/* {(invoice.age_gender.includes("6739")) ||(invoice.age_gender.includes("6740"))? this will apply for other than human because age is calculated from hazrat adam jab 6740 se b zyada ho gae to es me aur include dal den ge*/}
                  
                      {(invoice.age_gender.includes("6739")) ||(invoice.age_gender.includes("6740"))?

                                                <Text style={{ width: "45%" }}>N/A</Text>: <Text style={{ width: "45%" }}>{invoice.age_gender.split('|')[1]}</Text>}
                                               
                                                {/* <Text style={{ width: "45%" }}>{invoice.age_gender.split('|')[1]}</Text> */}
                </View>
                <View style={{
                    marginLeft: '5em',
                    paddingTop: '5em',
                    fontSize: 7,
                    flexDirection: "row",
                    width: "170em",
                    paddingTop: 2,
                    paddingBottom: 2,
                    borderBottomColor: 'black',
                    borderBottomWidth: 0.3,

                }}>
                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold', }}>Ref By</Text>
                    <Text style={{ width: "10%" }}>:</Text>
                    <Text style={{ width: "45%" }}>{invoice.Ref_By}</Text>
                </View>

                {
                    invoice.ReferenceNo == ""
                        ?
                        <Text></Text>
                        :
                        <View style={{
                            marginLeft: '5em',
                            paddingTop: '5em',
                            fontSize: 7,
                            flexDirection: "row",
                            width: "170em",
                            paddingTop: 2,
                            paddingBottom: 2,
                            borderBottomColor: 'black',
                            borderBottomWidth: 0.3,

                        }}>
                            <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>Reference No</Text>
                            <Text style={{ width: "10%" }}>:</Text>
                            <Text style={{ width: "45%" }}>{invoice.ReferenceNo}</Text>
                        </View>
                }
                <View style={{
                    marginLeft: '5em',
                    paddingTop: '5em',
                    fontSize: 7,
                    flexDirection: "row",
                    width: "170em",
                    paddingTop: 2,
                    paddingBottom: 2,
                    borderBottomColor: 'black',
                    borderBottomWidth: 0.3,

                }}>
                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold', }}>Specimen Date/Time</Text>
                    <Text style={{ width: "10%" }}>:</Text>
                    <Text style={{ width: "45%" }}>{invoice.speicmenDate[0] + " " + invoice.specimenTime[0]}</Text>
                </View>

                <View style={{
                    marginLeft: '5em',
                    paddingTop: '5em',
                    fontSize: 7,
                    flexDirection: "row",
                    width: "170em",
                    paddingTop: 2,
                    paddingBottom: 2,
                    borderBottomColor: 'black',
                    borderBottomWidth: 0.3,

                }}>
                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>Result Date/Time</Text>
                    <Text style={{ width: "10%" }}>:</Text>
                    <Text style={{ width: "45%" }}>{invoice.ResultDate[0] + " " + invoice.ResultTime[0]}</Text>
                </View>


            </View>


        </View>
      
        {/* add Group Title for every group title 3 nov 2022 */}
        {/* {invoice.Microbiology[title][0].newValueGroupTitle!=null &&   <View style={{
                            width: '100%', 
                            //for patient image 
                            // top: 125, 
                            top:invoice.PatientImage !==null?144:125,
                            position: 'absolute', 
                           // paddingLeft: 58,
                            // backgroundColor:"green"
                        }} fixed>
                            {console.log("###########################################",title)}
                            {console.log("###########################################",invoice)}

                            <InvoiceTitleNewGroup title={invoice.Microbiology[title][0].newValueGroupTitle} />
        </View>} */}
        {/* add Group Title for page only 5 nov 2022 */}

        {/* {invoice.GroupTitleNew!=null &&   <View style={{
                            width: '100%', 
                            //for patient image 
                            // top: 125, 
                            top:invoice.PatientImage !==null?144:125,
                            position: 'absolute', 
                           // paddingLeft: 58,
                            // backgroundColor:"green"
                        }} fixed>
                            {console.log("###########################################",title)}
                            {console.log("###########################################",invoice)}

                            <InvoiceTitleNewGroup title={invoice.GroupTitleNew} />
        </View>}  */}
                            {  invoice.GroupTitleNew=="" ? null : <InvoiceTitleNewGroup title={invoice.GroupTitleNew} />}
                            {  invoice.GroupTitleNew=="" ? null : <InvoiceTitleNewGroupLine title={invoice.GroupTitleNew} 
                        titleLength={invoice.GroupTitleNew.length}
                        />}
        <View style={{
            width: '100%', top:invoice.PatientImage !==null?144: 125, position: 'absolute', justifyContent: "center", marginLeft: 20

        }} fixed>
            <InvoiceTitle title={"MICROBIOLOGY"} />
        </View>

        <InvoiceItemsTable invoice={invoice} />
         {/* 12-aug-2023 bottom 0 he rehny dya yhan without logo me -50 kiya tha */}

        <View fixed style={{ position: 'absolute', width: '100%', bottom: 0 }}>


            <View >

                <Text fixed style={{ width: "100%", left: 0, paddingLeft: 58, position: "absolute", fontSize: 6 }} render={({ pageNumber, totalPages }) => (
                    "Page " + `${pageNumber} / ${totalPages}`
                )} />
                <View style={{ alignItems: 'flex-end', marginRight: -35 }}>
                    <Text style={{ fontSize: 6 }}>User ID : {capitalizeFirstLetter(invoice.DoctorSignature)} Printed on Date   {invoice.TodayDate} at {invoice.TodayTime}   Inv.Date: {invoice.InvoiceDate[0]}  Inv.Time {invoice.InvoiceTime[0]}</Text>
                    <Text style={{ color: 'red', bottom: 0, right: 0, fontSize: 7, fontFamily: 'Helvetica-Bold' }}>This is a digitally verified Report and does not require any Signature</Text>
                    <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Dr. Amna Afzal"}</Text>
                    <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>FCPS Microbiology</Text>
                    <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>Consultant Microbiologist</Text>
                </View>
            </View>

            <View>
                {/* 12-aug-2023 change image footerold k sth replcae kiya hieght 50% reduce ki image ki aur yhan hiegth 100 ki jga 70 ki */}
                <Image src={footer} style={{ width: "112%", height: 70 }} />
            </View>
        </View>

    </Page>)
}

export default Report