import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'
import LoginInfo from './LoginInfo'
import LoginCredientials from './LoginCredientials'

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    borderWidth: 0.5,
    fontFamily: "Roboto Regular",
    borderColor: 'black'
  },
  LoginTitle:{
    color: 'black',
    fontSize: 9,
    fontFamily:'Roboto Bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    textDecoration:'underline'
},
});

const InvoiceItemsTable = ({ invoice }) => (
  invoice.list.map((item) => {
    return <View>
      <InvoiceTitle title={item.title} />
      <View style={styles.tableContainer}>
        <InvoiceTableHeader date={invoice.result_date} />
        <InvoiceTableRow items={item.reports} />
      </View>
      <View style={{
        paddingLeft: 10,
        paddingTop: 10,
        marginTop: 5,
        borderWidth: 0.5,
        borderColor: 'black'
      }}>
        <Text style={{
          fontSize: 8,
          fontFamily: 'Roboto Bold'

        }}>Specimen:</Text>
        <Text
          style={{
            padding: 2,
            paddingRight: 10,
            paddingLeft: 10,
            fontFamily: "Roboto Regular"
          }}
        >{`Nasopharyngeal Swab`}</Text>

        <Text style={{
          fontSize: 8,
          fontFamily: 'Roboto Bold'

        }}>Methodology:</Text>
        <Text style={{
          fontSize: 8,
          fontFamily: 'Roboto Bold',
          paddingLeft: 10
        }}>RNA Extraction:</Text>
        <Text
          style={{
            padding: 2,
            paddingRight: 20,
            paddingLeft: 20,
            fontFamily: "Roboto Regular"
          }}
        >{`RNA is isolated & purified using an automated nucleic acid extraction system based on magnetic particle technology.`}</Text>
        <Text style={{
          fontSize: 8,
          fontFamily: 'Roboto Bold',
          paddingLeft: 10
        }}>Amplification:</Text>
        <Text
          style={{
            padding: 2,
            paddingRight: 20,
            paddingLeft: 20,
            fontFamily: "Roboto Regular"
          }}
          // 03-aug-2023
        // >{`Test is performed on a state of the art Real Time PCR system using the MutaPLEX Coronavirus RT PCR kit from Immundiagnostik, Germany. This kit contains specific primers & dual labelled probes for the amplification of RNA of SARS-CoV-2 (RdRP gene & S gene) & the RNA of subgenus sarbecoviruses (E gene) extracted from biological samples of upper respiratory tract. Both E gene & RdRP gene are target sequences of the viral genome recommended by WHO. The simultaneous detection of 3 target sequences (RdRP gene, S gene & E gene) increases the diagnostic reliability, even in case of target sequence mutations. Additionally, Mutaplex kit contains control RNA (internal process control, IPC) and a housekeeping gene (Beta-actin, multi species) to validate the quality of nucleic acid extraction & quality of sample collection and transport respectively.`}</Text>
        >{`Test is performed on a state of the art Real Time PCR system using the Coronavirus RT PCR kit. This kit contains specific primers & dual labelled probes for the amplification of RNA of SARS-CoV-2 (RdRP gene & S gene) & the RNA of subgenus sarbecoviruses (E gene) extracted from biological samples of upper respiratory tract. Both E gene and RdRP gene are target sequences of the viral genome recommended by WHO. The simultaneous detection of 3 target sequences (RdRP gene, S gene & E gene) increases the diagnostic reliability, even in case of target sequence mutations. Additionally, the kit contains control RNA (internal process control, IPC) to validate the quality of nucleic acid extraction.`}</Text>



        <Text style={{
          fontSize: 8,
          fontFamily: 'Roboto Bold'

        }}>Comments/Recommendations:</Text>
        <View
          style={{
            padding: 1,
            flexDirection: 'row',
            paddingRight: 10,
            paddingLeft: 10,
            fontFamily: "Roboto Regular"
          }}
        ><Text style={{
          padding: 1,
          fontFamily: 'Roboto Bold'
        }}>{"o  "}</Text><Text style={{ paddingTop: 2.5 }}>{`A negative result must be interpreted along with clinical observation, patient history and epidemiological information.`}</Text></View>
        <View
          style={{
            padding: 2,
            flexDirection: 'row',
            paddingRight: 10,
            paddingLeft: 10,
            fontFamily: "Roboto Regular"
          }}
        ><Text style={{
          padding: 1,
          fontFamily: 'Roboto Bold'
        }}>{"o  "}</Text><Text style={{ paddingTop: 2.5 }}>{`A single negative result might not exclude the possibility of corona virus infection. A repeat test may be required if symptoms persist.`}</Text></View>
        <View
          style={{
            padding: 1,
            flexDirection: 'row',
            paddingRight: 10,
            paddingLeft: 10,
            fontFamily: "Roboto Regular"
          }}
        ><Text style={{
          padding: 1,
          fontFamily: 'Roboto Bold'
        }}>{"o  "}</Text><Text style={{ paddingTop: 2.5 }} >{`In case of positive result, it is strongly advised that patient should stay at home under self-quarantine and maintain social distancing along with
    additional testing as deemed necessary by treating physician.`}</Text></View>

        <View
          style={{
            padding: 1,
            flexDirection: 'row',
            paddingRight: 10,
            paddingLeft: 10,
            fontFamily: "Roboto Regular"
          }}
        ><Text style={{  padding: 1,fontFamily: 'Roboto Bold' }}>{"o  "}</Text ><Text style={{ paddingTop: 2.5 }}>{`In case of the patient develops shortness of breath he/she should immediately seek medical advice`}</Text></View>
       
        <View
          style={{
            padding: 1,
            flexDirection: 'row',
            paddingRight: 10,
            paddingLeft: 10,
            fontFamily: "Roboto Regular"
          }}
        ><Text style={{  padding: 1,fontFamily: 'Roboto Bold' }}>{"o  "}</Text><Text style={{ paddingTop: 2.5 }}>{`Results of PCR studies performed in one laboratory should not be compared with those performed in another laboratory as the kits/methodologies
    used in different laboratories may not have the same sensitivity and specificity.`}</Text></View>

      </View>
      <View
          style={{
            padding: 1,
            flexDirection: 'row',
            width:'100%',
            backgroundColor:'#FFFFE1',
            fontFamily: "Roboto Regular",
            borderLeftWidth:0.5,
            borderRightWidth:0.5,
            borderBottomWidth:0.5
          }}
        ><Text style={{textAlign:'center', fontFamily:"Roboto Bold", color:"red", fontSize:9}}>{`Note: For External Quality Assurance We are participating in College of American Pathologists (CAP’s) EQA Program for SARS-CoV-2.`}</Text></View>


    
    </View>
  })

);

export default InvoiceItemsTable