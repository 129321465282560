import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
       justifyContent:'center',
    },
    reportTitle:{
        color: 'black',
        fontSize: 11,
        backgroundColor: '#F2F0F0',
        border:1,
        fontFamily:'Helvetica-Bold',
        paddingLeft:20,
        paddingRight:20,
        paddingTop:5
    }
  });


  const InvoiceTitle = ({title}) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title}</Text>
    </View>
  );
  
  export default InvoiceTitle