import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        // 24-july-2023 for start
       justifyContent:'center', 
      //  justifyContent:"flex-start",
       
// 24-july-2023 for start
       margin:2,
      // marginBottom:60

       
    },
    reportTitle:{
        color: 'black',
        // 26-july-2023
        // fontSize: 11,
        fontSize: 12.5,

        // 24-july-2023
        // backgroundColor: '#F2F0F0',
        //border:1,
        // borderBottom:1,
        fontFamily:'Helvetica-Bold',
        // 24-july-2023
        // paddingLeft:20,
        // paddingRight:20,
        //  paddingTop:5
    }
  });


  const InvoiceTitle = ({title}) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title}</Text>
    </View>
  );
  
  export default InvoiceTitle