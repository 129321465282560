import React,{ Component } from 'react';


import MaterialTable from 'material-table';
import 'whatwg-fetch';

import ReactLoading from 'react-loading';


export default class Lab_Tests extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            columns: [
                { title: 'Test Name', field: 'Test_Name',  defaultSort:'asc'},
                { title: 'Test Code', field: 'Test_Code' },
                { title: 'Sample Type', field: 'Sample_Type',
                  render: rowData => rowData.Sample_Type ? rowData.Sample_Type : 'N/A' 

                 },
                { title: 'Special Remarks', field: 'special_comments' ,
                  render: rowData => rowData.special_comments ? rowData.special_comments : 'N/A' 
                },

                { title: 'Delivery Time', field: 'Delivery_Time'},
                {
                  title: 'Test Price (PKR)',
                  field: 'Test_Price',
                  type: 'numeric' 
                },
              ],
              data: [
                
              ],
              loading:true
                
        }
        this.getData=this.getData.bind(this)
    }
    
    getData() {
        var columns = this.state.columns;
        var rows = this.state.data;
        return { columns, rows};
      }
      componentWillMount(){
        //  fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetTestsPriceList?vQuery={%22PIN%22:%22%22,%22PhoneNo%22:%22%22,%22PatientName%22:%22%22,%22RegDateFrom%22:%222000-02-01T04:08:27.995Z%22,%22RegDateTo%22:%222020-08-12T04:08:27.995Z%22}&vUID=120720001&vRole=Patient"
        
        // )
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebGetPriceListsBranchPanelS", {
          method: "POST",
          'Content-Type': "application/json",
          body: JSON.stringify({
    
            "branch_id": 1,
            "panel_code": ""
    
          })
        })
        .then((res)=>res.json())
         .then((result)=>{
          console.log(result)
           this.setState({data:result})
           this.setState({loading:false})
         })
       
      }

    render(){
        return (
          <>
{this.state.loading ?
<center>
  <ReactLoading type={'spinningBubbles'} color={'#196c90'}  height={'10em'} width={'10em'}/>
  </center>
  :
  <MaterialTable
  options={{
    // selection: true,
    // paging:true,
    // minBodyHeight:40,
    headerStyle: {
      backgroundColor: '#196c91',
      color: '#FFF'
    }
   
    // showSelectAllCheckbox:SelectionControl
  }}
      title="Find a Test / Service"
      columns={this.state.columns}
      data={this.state.data}
    
  
          />
}
</>
            
        )
    }
}