import React, { useEffect } from 'react';

import { CSVLink } from "react-csv";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import Button from '@material-ui/core/Button';


import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import ReactExport from 'react-data-export';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const columns = [
  { id: 'mrn', label: 'MRN' },
  { id: 'pin', label: 'PIN' },
  { id: 'name', label: 'Patient Name' },
  { id: 'age', label: 'Gender' },
  { id: 'refby', label: 'RefBy' },
  { id: 'refNo', label: 'RefNo' },
 { id: 'cnic', label: 'CNIC' },
 { id: 'passport', label: 'Passport' },
 { id: 'flight_no', label: 'Flight#' },
 { id: 'flight_date', label: 'Flight Date' },
 { id: 'reference_no', label: 'Booking Reference#' },
 { id: 'download', label: 'Download' },
 { id: 'airport', label: 'Airport' },
 { id: 'branch', label: 'Registration Branch' },
 { id: 'booking_date', label: 'Registration Date' },
  { id: 'result_date', label: 'Report Date' },
  { id: 'result', label: 'Result' },
  { id: 'airline', label: 'AirLine' }

];
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}


export default function Reports() {
  const classes = useStyles();
  const [date, setDate] = React.useState(new Date())
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(true)
  const [branchDetail, setBranchDetail] = React.useState([]);
  const [data_export, setData_Export] = React.useState([])
  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branch, setBranch] = React.useState('All')
  const [state, setState] = React.useState({
    status: 'All',
  });


  const multiDataSet = [
    {
   
      columns: [
        { title: "MRN", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true, color: "87CEEB", underline: true } } },//pixels width 
        { title: "PIN", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },//char width 
        { title: "Name", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Age/Gender", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "RefBy", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "RefNo", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "CNIC", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Passport", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Flight#", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Flight Date", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Booking Reference#", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Airport", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Registration Branch", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Registration Date", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Report Date", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Result", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "AirLine", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },

      ],
      data: data_export
    }
  ];

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  const getData = async (data) => {


    var rows_export = []
    var d = date.toISOString()
    var date_ = new Date(d).ddmmyyy()

    var res = await data.map((item) => {
      var obj = [
        
        { value: item.mrn, style: { font: { sz: "14" }, alignment: { horizontal: 'center' } } },
        { value: item.pin, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.name, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.age, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.refby, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.refNo, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.cnic, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.passport, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.flight_no, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.flight_date, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.reference_no, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.airport, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.branch, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
       
        { value: item.booking_date, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.result_date, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
       
        { value: item.result, style: { font: { sz: "14", color: '#87ceeb' }, alignment: { horizontal: 'center' } } },
        { value: item.airline, style: { font: { sz: "14", color: '#87ceeb' }, alignment: { horizontal: 'center' } } }

      ]
      rows_export.push(obj)

      return

    })

    Promise.all(res).then(() => {
      setData_Export(rows_export)
    })
  }
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {


    fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver"
    ).then((res) => res.json())
      .then((result) => {
        setBranchDetail(result)
        var vSearchStr={
          "PIN":"",
          "PhoneNo":"",
          "PatientName":"",
          "RegDateFrom":date.toISOString(),
          "RegDateTo":date.toISOString(),
          "vBranchID":"0",
          "AirlineID":6,
          "Airline_Type":""
      }
         fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebGetTestsAirlineGeneric",{
          method:"POST",
          'Content-Type':"application/json",
          body:JSON.stringify(vSearchStr)
        }).then((res) => res.json())
          .then((result) => {
            var the_rows = []
          
            result.map((data) => {
              var res_date = new Date(data.Result_Date).ddmmyyy()
              var flight_date = new Date(data.Flight_Date).ddmmyyy()
              var test_res = data.Test_Result

              if (data.Flight_Date == "0000-00-00T00:00:00.000Z") {
                flight_date = ""
              }
              if (data.Result_Date == "0000-00-00T00:00:00.000Z") {
                res_date = ""
              }
              if (data.Test_Result == "Covid19 NOT Detected") {
                test_res = 'Negative'
              }
              if (data.Test_Result == "Covid19 Detected") {
                test_res = 'Positive'
              }
              var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
              var booking_time = msToTime(data.ACCESS_TiME)
              if (test_res == "") {
                test_res = "Results not Ready Yet"
              }


              var flight_date = new Date(data.Flight_Date).ddmmyyy()
              var flight_time = msToTime(data.Flight_Time)
              var br_title=""
       
              if(data.B_TiTLE=="METROPOLE"){
                br_title="Islamabad"
              }else{
      
             br_title=data.B_TiTLE.split('MPL,')[1]
              }
              if(data.B_TiTLE.toLowerCase().includes('genomic rwp') || data.B_TiTLE.toLowerCase().includes('ddlc') || data.B_TiTLE.toLowerCase().includes('biogene') || data.B_TiTLE.toLowerCase().includes('device solutions') || data.B_TiTLE.toLowerCase().includes('medikay') || data.B_TiTLE.toLowerCase().includes('blue area') || data.B_TiTLE.toLowerCase().includes('mpl-mhc')){
                br_title="Islamabad"
                          }
               if(data.B_TiTLE.toLowerCase().includes('6th road rwp') || data.B_TiTLE.toLowerCase().includes('falak air') || data.B_TiTLE.toLowerCase().includes('abrar')   ){
                            br_title="Rawalpindi"
                          }
           
              var obj = {
               
                mrn: data.SYSTEM_ID,
                pin: data.Invoice_ID,
                name: data.NAME_F + " " + data.NAME_L,
                branch:br_title,
                age:data.GENDER,
                refby:"Qatar Airways",
                refNo:"",
                cnic:data.CNIC,
                airport:data.Airport,
                booking_date: booking_date,
                result_date: res_date,
                reference_no: data.Ticket_no,
                flight_no: data.Flight_no,
                flight_date: flight_date,
                airline:"QR",
                flight_time: flight_time,
                destination: data.Destination,
                passport: data.Passport_No,
                result: test_res,
                download: <center>
                  <Button variant="contained" color="secondary" disableElevation onClick={() => {
                    if (data.Test_Status == 'Results Ready') {
                      window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)

                    } else {
                      alert('Results not Ready!!')
                    }
                  }} >
                    <  PictureAsPdfSharpIcon />
                  </Button>
                </center>
              }
              the_rows.push(obj)

            })

            setRows(the_rows)
            setOrg_Rows(the_rows)
            setLoading(false)
            getData(the_rows)
          })
      })
  }, [])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes();
    var hh = this.getHours();

    return [
      (hh > 9 ? '' : '0') + hh,
      (mm > 9 ? '' : '0') + mm
    ].join(':');
  };

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  return (
    <Paper className={classes.root}>

      <GridContainer style={{ padding: '2em' }}>
        <GridItem xs={6} sm={6} md={3} lg={3}>
          <InputLabel className={classes.label}>
            <b>  Date</b>
          </InputLabel>

          <FormControl fullWidth>
            <Datetime
              inputProps={{ placeholder: "Summary of Date" }}
              value={date}
              timeFormat=""
              onChange={(date) => {
                setDate(date)
              }}
              dateFormat='DD-MM-YYYY'
            />
          </FormControl>
        </GridItem>



        <GridItem xs={6} sm={6} md={3} lg={3} style={{ margin: 'auto' }} >

          <Button variant="contained" color="primary" disableElevation
            onClick={() => {
              var vSearchStr={
                "PIN":"",
                "PhoneNo":"",
                "PatientName":"",
                "RegDateFrom":date.toISOString(),
                "RegDateTo":date.toISOString(),
                "vBranchID":"0",
                "AirlineID":6,
                "Airline_Type":""
            }
               fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebGetTestsAirlineGeneric",{
                method:"POST",
                'Content-Type':"application/json",
                body:JSON.stringify(vSearchStr)
              }).then((res) => res.json())
                .then((result) => {
                  var the_rows = []
          
            result.map((data) => {
              var res_date = new Date(data.Result_Date).ddmmyyy()
              var flight_date = new Date(data.Flight_Date).ddmmyyy()
              var test_res = data.Test_Result

              if (data.Flight_Date == "0000-00-00T00:00:00.000Z") {
                flight_date = ""
              }
              if (data.Result_Date == "0000-00-00T00:00:00.000Z") {
                res_date = ""
              }
              if (data.Test_Result == "Covid19 NOT Detected") {
                test_res = 'Negative'
              }
              if (data.Test_Result == "Covid19 Detected") {
                test_res = 'Positive'
              }
              var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
              var booking_time = msToTime(data.ACCESS_TiME)
              if (test_res == "") {
                test_res = "Results not Ready Yet"
              }


              var flight_date = new Date(data.Flight_Date).ddmmyyy()
              var flight_time = msToTime(data.Flight_Time)
              var br_title=""
       
              if(data.B_TiTLE=="METROPOLE"){
                br_title="Islamabad"
              }else{
      
             br_title=data.B_TiTLE.split('MPL,')[1]
              }
              if(data.B_TiTLE.toLowerCase().includes('genomic rwp') || data.B_TiTLE.toLowerCase().includes('ddlc') || data.B_TiTLE.toLowerCase().includes('biogene') || data.B_TiTLE.toLowerCase().includes('device solutions') || data.B_TiTLE.toLowerCase().includes('medikay') || data.B_TiTLE.toLowerCase().includes('blue area') || data.B_TiTLE.toLowerCase().includes('mpl-mhc')){
                br_title="Islamabad"
                          }
               if(data.B_TiTLE.toLowerCase().includes('6th road rwp') || data.B_TiTLE.toLowerCase().includes('falak air') || data.B_TiTLE.toLowerCase().includes('abrar')   ){
                            br_title="Rawalpindi"
                          }
           
              var obj = {
               
                mrn: data.SYSTEM_ID,
                pin: data.Invoice_ID,
                name: data.NAME_F + " " + data.NAME_L,
                branch:br_title,
                age:data.GENDER,
                refby:"Qatar Airways",
                refNo:"",
                cnic:data.CNIC,
                airport:data.Airport,
                booking_date: booking_date,
                result_date: res_date,
                reference_no: data.Ticket_no,
                flight_no: data.Flight_no,
                flight_date: flight_date,
                flight_time: flight_time,
                destination: data.Destination,
                passport: data.Passport_No,
                result: test_res,
                download: <center>
                  <Button variant="contained" color="secondary" disableElevation onClick={() => {
                    if (data.Test_Status == 'Results Ready') {
                      window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)

                    } else {
                      alert('Results not Ready!!')
                    }
                  }} >
                    <  PictureAsPdfSharpIcon />
                  </Button>
                </center>
              }
              the_rows.push(obj)

            })

            setRows(the_rows)
            setOrg_Rows(the_rows)
            setLoading(false)
            getData(the_rows)
                })
            }}

          >
            Seach<SearchIcon />
          </Button>

        </GridItem>
        <GridItem xs={6} sm={6} md={3} lg={3}>

        </GridItem>
        <GridItem md={3} lg={3} style={{ margin: 'auto', float: 'right' }} >
          <ExcelFile filename="COVID-19 by PCR" element={<Button
            color="primary"
            style={{ backgroundColor: 'green', color: "white" }}

          >
            Export Excel
              </Button>}>
            <ExcelSheet dataSet={multiDataSet} name="COVID-19 by PCR" />
          </ExcelFile>

        </GridItem>
      </GridContainer>

      {loading ?
        <center>
          <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
        </center>
        :
        <>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code + Math.random()} >
                      {columns.map((column) => {
                        const value = row[column.id];

                        return (

                          <TableCell key={column.id} align={column.align} >
                            {value}
                          </TableCell>

                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      }
    </Paper>
  );
}
