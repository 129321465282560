import React,{useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import useWindowDimensions from "../../useWindowDimensions";
import Button from "components/CustomButtons/Button.js";
import "./OurGallery.css"



// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import { BorderColor } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(false);
  

  return (
    <div className={classes.section} style={{padding:"0px 0px",paddingBottom:"2rem"}}>
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={12} lg={12} style={{marginBottom:"0%"}}>
      <h2 className={classes.title} style={{marginTop:"0px"}}>Gallery</h2>
          </GridItem>
          {/* 1st */}
          <GridItem xs={12} sm={6} md={4} lg={4} style={{display:"flex", justifyContent:"center",alignItem:"center",
          // marginTop:"2%"
          }}>
          <div style={{height:"95%",width:"95%",}} className="image-box">

         <img
                src={require("assets/img_stock/New2023/Gallery/1.jpg")}
                alt=""
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120
            
            }}
              />
</div>
          </GridItem>
          {/* 2nd */}

          <GridItem xs={12} sm={6} md={4} lg={4} style={{display:"flex", justifyContent:"center",alignItem:"center",
            marginTop:width<500?"5%":width<600?"2%":"0%"
          }}>
          <div style={{height:"95%",width:"95%"}}>

         <img
                src={require("assets/img_stock/New2023/Gallery/2.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
                 


                 

</div>
          </GridItem>  
          {/* 3rd */}

            <GridItem xs={12} sm={6} md={4} lg={4} style={{display:"flex", justifyContent:"center",alignItem:"center",
            marginTop:width<500?"5%":width<950?"2%":"0%"
            }}>
          <div style={{height:"95%",width:"95%"}}>

         <img
                src={require("assets/img_stock/New2023/Gallery/5.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
              

                 

</div>
          </GridItem>  
          {/* 4th */}

            <GridItem xs={12} sm={6} md={4} lg={4} style={{display:"flex", justifyContent:"center",alignItem:"center",
            // marginTop:"2%"
            marginTop:width<500?"5%":"2%"
            }}>
          <div style={{height:"95%",width:"95%"}}>

         <img
                src={require("assets/img_stock/New2023/Gallery/66.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
                 

                 

</div>
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={4} style={{display:"flex", justifyContent:"center",alignItem:"center",
          // marginTop:"2%"
          marginTop:width<500?"5%":"2%"
          }}>
          <div style={{height:"95%",width:"95%"}}>

         <img
                src={require("assets/img_stock/New2023/Gallery/9.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120
            }}
              />
                

                 

</div>
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={4} style={{display:"flex", justifyContent:"center",alignItem:"center",
          // marginTop:"2%"
          marginTop:width<500?"5%":"2%"
          }}>
          <div style={{height:"95%",width:"95%"}}>

         <img
                src={require("assets/img_stock/New2023/Gallery/10.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                borderColor:"#196c90",
                borderWidth:2,
                borderStyle:"solid",
                //borderRadius:width<1280?240:120

            
            }}
              />
                
                 


                 

</div>
          </GridItem>
      
    
        
      </GridContainer>
      <GridContainer>
          <Dialog
            // classes={{
            //   root: this.classes.center,
            //   paper: this.classes.modal
            // }}
            open={open}
            // TransitionComponent={Transition}
            keepMounted
            maxWidth={'xl'}
            fullWidth={true}
            // onClose={() => this.setState({ alert: false })}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <h3><b>Quotation</b></h3>

            </DialogTitle>
            <DialogContent
              id="classic-modal-slide-description"
              className={classes.modalBody}

            >
              <center>
                {/* {
                  this.state.selected.length>0
                  ?
                  <Fragment>
                  <PDFViewer width="1000" height="1000" className="app" >
                    <Invoice invoice={this.state.selected} discount={this.state.discount} branchName={this.state.branchName} panelName={this.state.panelName} />
                  </PDFViewer>
                </Fragment>
                :
                null

                } */}
             
              </center>
            </DialogContent>
            <DialogActions className={classes.modalFooter}>

              <Button
                onClick={() => setOpen(false)}
                color="danger"
                simple
              >
                <b>Close</b>
              </Button>
            </DialogActions>
          </Dialog>
        </GridContainer>
    
     
     
    </div>
  );
}
