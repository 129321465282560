import React ,{useEffect} from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import useWindowDimensions from "../../useWindowDimensions";

import COVID19_PCR from '../COVID19_PCR/Covid19_PCR.js'
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import FreeHS_Msg from '../Components/FreeHS_Msg.js'
import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import molecule_1 from 'assets/img/animations/virus (13).png';


import molecule_6 from 'assets/img/animations/virus (6).png';

import molecule_7 from 'assets/img/animations/virus (9).png';


import report from 'assets/img/lab_test.png';


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Echocardiography(props) {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  const { ...rest } = props;
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },[])
  return (
    <div style={{backgroundColor:"#196c90"}}>
      <Header
        // color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img_stock/New2023/Department/Echocardio.jpg")} style={{marginTop:width<500?90:100}}
>
        <div className={classes.container}>
          
          <GridContainer>
            
            <GridItem>
            {/* <img src={molecule_1} className="funfact-two__virus" alt="" style={{
  width: '5em',
  height: 'auto', right:'30em'}}/>
       <img src={molecule_6} className="funfact-two__virus__5" alt="" style={{  width: '5em',
  height: 'auto',}}/>
       <img src={molecule_7} className="funfact-two__virus__7" alt="" style={{ width: '5em',
  height: 'auto',top:'10em'}}/>    */}
      
       
           {width<500?
              <h2 className={classes.title} style={{marginTop:-40}}>Echocardiography</h2>
:
<h1 className={classes.title} style={{marginTop:-20}}>Echocardiography</h1>
}
<p   style={{color:'white'}}>  Echo (Echocardiography) under supervision of classified
Cardiologists, using state-of-the-art equipment, provides
accurate and timely assessment of heart function to
identify various heart conditions, including heart valve
problems, congenital heart defects and problems with
heart muscle function. With the latest technology and
expert staff, Metropole's Echo Services ensure that
patients receive the best possible care for their heart
health.
          
            
              </p>
              {/* <Button
                color="danger"
                size="lg"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Watch video
              </Button> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      {/* <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?-120:-60}}> */}
      <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?-150:-200}}>

        <div className={classes.container} style={{paddingTop:"1em", paddingBottom:"1em"}} >
       
            {/* <br /> */}
  <GridContainer>
  <GridItem xs={12} sm={12} md={12} lg={12}  style={{display:"flex",justifyContent:"center",alignItems:"center"}}>

<h3 className={classes.title} style={{color:'black',textAlign:"center",marginTop:"0px"}}>Technologies In Use</h3>
             {/* <h3 className={classes.title} style={{color:'black',marginTop:"0px"}}>Echocardiography</h3> */}
             {/* <p   style={{color:'black'}}>  Echo (Echocardiography) under supervision of classified
Cardiologists, using state-of-the-art equipment, provides
accurate and timely assessment of heart function to
identify various heart conditions, including heart valve
problems, congenital heart defects and problems with
heart muscle function. With the latest technology and
expert staff, Metropole's Echo Services ensure that
patients receive the best possible care for their heart
health.
          
            
              </p> */}
          </GridItem>
          <GridItem xs={0} sm={2} md={4} lg={4}>
</GridItem>
          <GridItem xs={12} sm={8} md={4} lg={4} style={{maxHeight:600,marginTop:10}}>
          <img
                src={require("assets/img_stock/New2023/MachineImages/Echocardiography.png")}
                style={{ 
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            }}
              />
              <p   style={{color:'black',marginTop:10}}>Echocardiography System, Vivid™ T8,
by GE Healthcare, USA</p>
          </GridItem>
      </GridContainer>
      {/* <br /> */}
      
            {/* <FreeHS_Msg /> */}
          {/* <ProductSection />
          <TeamSection />
          <WorkSection /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
