import React ,{useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import COVID19_PCR from '../COVID19_PCR/Covid19_PCR.js'
import useWindowDimensions from "../../useWindowDimensions";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import FreeHS_Msg from '../Components/FreeHS_Msg'
import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
// import molecule_1 from 'assets/img/animations/virus (13).png';


// import molecule_6 from 'assets/img/animations/virus (6).png';

// import molecule_7 from 'assets/img/animations/virus (9).png';
import team1 from "assets/img_stock/New2023/Department/1new1.jpg";

// import report from 'assets/img/lab_test.png';


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Clinic_Chem(props) {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  const { ...rest } = props;
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },[])
  return (
    <div style={{backgroundColor:"#196c90"}}>
      <Header
        // color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          // when scroll up to 400px color change into white otherwise its transparent that are define in 6 line above
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      {/*style={{marginTop:100}}  */}
      <Parallax filter image={team1} style={{marginTop:width<500?90:100}}>
        <div className={classes.container}>
          
          <GridContainer>
            
            <GridItem>
         
      
       
           {width<500?
              <h2 className={classes.title} style={{marginTop:-40}}>Clinical Chemistry & Immunology</h2>
:
width<950?
  <h3 className={classes.title} style={{marginTop:-40}}>Clinical Chemistry & Immunology</h3>
:
<h1 className={classes.title} style={{marginTop:-40}}>Clinical Chemistry & Immunology</h1>
}
              {/* <br /> */}
              <p   style={{color:'white',textAlign:"justify"}}>The Clinical Chemistry & Immunology Section of the Pathology Department is core of the
organization, specializes in performing a variety of laboratory assays that provide crucial
information to physicians for making informed decisions. This section is equipped with advanced
technology and staffed by highly qualified professionals. The assays offered in this section
include, but are not limited to, routine and specialized clinical chemistry tests, hormones, tumor
markers, vitamins, infectious diseases and autoimmune markers. 
          
             
             </p>
           
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      {/* <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?-120:-60}}> */}
      <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?-150:-200}}>

        <div className={classes.container} style={{paddingTop:"1em", paddingBottom:"1em"}} >
        
            {/* <br />
            <br />

            <br /> */}

  <GridContainer>
         <GridItem xs={12} sm={12} md={12} lg={12} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
             {/* <h3 className={classes.title} style={{color:'black',marginTop:"0px"}}>Clinical Chemistry & Immunology</h3> */}
             {/* <p   style={{color:'black',textAlign:"justify"}}>The Clinical Chemistry & Immunology Section of the Pathology Department is core of the
organization, specializes in performing a variety of laboratory assays that provide crucial
information to physicians for making informed decisions. This section is equipped with advanced
technology and staffed by highly qualified professionals. The assays offered in this section
include, but are not limited to, routine and specialized clinical chemistry tests, hormones, tumor
markers, vitamins, infectious diseases and autoimmune markers. 
          
             
             </p> */}
             <h3 className={classes.title} style={{color:'black',textAlign:"center",marginTop:"0px",}}>Technologies In Use</h3>

          </GridItem>
          {/* <GridItem xs={12} sm={12} md={6} lg={6}>
          <img
                src={require("assets/img_stock/bio_chem_1.JPG")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
          </GridItem> */}
      </GridContainer>
      <br />
      <GridContainer>
         <GridItem xs={12} sm={12} md={6} lg={4} >
         <img
                src={require("assets/img_stock/New2023/MachineImages/ClinicalChemistry/1.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"100%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
                 <p   style={{color:'black',marginTop:10,textAlign:"center"}}>Cobas 6000, Modular Chemistry & Immunoassay Analyzer
by Roche Switzerland</p>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",alignItem:"center",flexDirection:"column"}}>
         <img
                src={require("assets/img_stock/New2023/MachineImages/ClinicalChemistry/2.jpg")}
                style={{ 
                maxWidth: "100%",
                // maxHeight:"50%",
                alignSelf:"center",
                height: "auto",
                // borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
                 <p   style={{color:'black',marginTop:10,textAlign:"center"}}>ARCHITECT i1000SR Automated Immunoassay
Analyzer by Abbott USA</p>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",alignItem:"center",flexDirection:"column"}}>

         <img
                src={require("assets/img_stock/New2023/MachineImages/ClinicalChemistry/3.jpg")}
                style={{ 
                maxWidth: "100%",
                // maxHeight:"50%",
                height: "auto",
                // borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
                 <p   style={{color:'black',marginTop:10,textAlign:"center"}}>iSYS Multi-Discipline Automated Specialty
Immunoassay System by IDS France</p>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",alignItem:"center",flexDirection:"column"}}>

         <img
                src={require("assets/img_stock/New2023/MachineImages/ClinicalChemistry/4.jpg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                // maxHeight:"50%",

                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
                 <p   style={{color:'black',marginTop:10,textAlign:"center"}}>Chorus Trio, Immunoassay system
by Diesse Italy</p>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",alignItem:"center",flexDirection:"column"}}>
         <img
                src={require("assets/img_stock/New2023/MachineImages/ClinicalChemistry/5.jpg")}
                style={{ 
                maxWidth: "100%",
                // maxHeight:"50%",
                alignSelf:"center",
                height: "auto",
                // borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
                 <p   style={{color:'black',marginTop:10,textAlign:"center"}}>Blue Diver, Automated Microarray
Immunoblotting system by D-Tek Belgium</p>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",alignItem:"center",flexDirection:"column"}}>

         <img
                src={require("assets/img_stock/New2023/MachineImages/ClinicalChemistry/6.jpg")}
                style={{ 
                maxWidth: "100%",
                // maxHeight:"50%",
                height: "auto",
                // borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
                 <p   style={{color:'black',marginTop:10,textAlign:"center"}}>Alegria, Automated Immunoassay
system by Orgentec Germany</p>
          </GridItem>
          {/* 7th */}
          <GridItem xs={12} sm={12} md={6} lg={4} style={{display:"flex",alignItem:"center",flexDirection:"column"}}>

<img
       src={require("assets/img_stock/New2023/MachineImages/ClinicalChemistry/7.jpg")}
       style={{ 
       maxWidth: "100%",
       // maxHeight:"50%",
       height: "auto",
       // borderRadius: "calc(.25rem - 1px)",
       boxShadow:
       "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
   
   }}
     />
        <p   style={{color:'black',marginTop:10,textAlign:"center"}}>Vidas 3 Automated Immunoassay Analyser by Biomerieux USA</p>
 </GridItem>
          
      </GridContainer>
      {/* free home smapling msg that are display in below */}
            {/* <FreeHS_Msg /> */}
          {/* <ProductSection />
          <TeamSection />
          <WorkSection /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
