import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import useWindowDimensions from "../../useWindowDimensions";


// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
const { width } = useWindowDimensions();

  return (
    <div className={classes.section} style={{paddingBottom:"0px"}}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6} lg={6} style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
         
          {/* <br/> */}
          <div style={{width:width<500?"100%":width<950?"100%":"80%",display:"flex",justifyContent:"center",flexDirection:"column"}} >
          <h2 className={classes.title} style={{marginTop:width<500?"0px":width<1200?"0px":width<1270?"-50px":"-100px"}}>CEO's Message</h2>
          <h5 className={classes.description} style={{textAlign:"justify",color:"black",marginLeft:width<950?"0%":"5%"}}>
          It is my pleasure to introduce you to Metropole Laboratories Private Ltd, a state of the art pathology
laboratory and diagnostic services company, located in the heart of the Capital city. At MPL, we are
devoted to provide the highest quality diagnostic services in a timely and professional manner. Our facility
is equipped with the latest and top of the line technologies backed by a team of greatly expert consultants
& health professionals. We offer a comprehensive range of diagnostic services, including a diverse
selection of routine & specialized laboratory tests, as well as a variety of advanced radiology & imaging
services. Our services menu is constantly expanding to keep pace with the evolving healthcare needs. We
are eager to establish partnerships with organizations & clinical specialists as their preferred diagnostic
service provider, in order to contribute to improved clinical decision-making and advanced patient care.
We strive to stretch our services beyond your expectations to accomplish our ultimate objective to provide
you outstanding experiences while being served at Metropole.
          </h5>
          </div>
          {/* <h2 className={classes.title}>Vision</h2>
          <h5 className={classes.description} style={{textAlign:"justify",color:"black"}}>
          At Metropole, our vision is to be the premier and most credible diagnostic service provider in
Pakistan, recognized for offering reliable, accessible and affordable services, to contribute in
disease prevention through precise and prompt diagnosis, ultimately leading to build a healthier
nation.
          </h5> */}
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6} style={{height:'50%',display:"flex",flexDirection:"column"}}>
         <div  style={{height:'50%',marginTop:20,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
          <img
                src={require("assets/img_stock/New2023/PersonImage/DrAmnaNew.jpeg.jpg")}
                style={{ 
                maxWidth:width<500?"100%": "80%",
                height: "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
              <h2 style={{color:"black",fontWeight:"bold"}}>Dr. Amna Afzal</h2>
              <p style={{color:"black",marginTop:-10}}>MBBS, DipRCPath Microbiology</p>
              <p style={{color:"black",marginTop:-10}}>FCPS Microbiology</p>
              <p style={{color:"black",marginTop:-10}}>Consultant Microbiologist</p>



              </div>
          </GridItem>
      </GridContainer>
     
     
    </div>
  );
}
