import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
  titleContainer:{
  //   flexDirection: 'row',
  //  justifyContent:'end',
  //  alignItems:"end",
   //margin:2,
   //marginTop:0,
  //  top:110,
   left:28.5,
  top:108,
  // left:26,
    // backgroundColor:"green",
    // height:20,
//     width:180,
// borderStyle:"solid",
// borderBottomColor: 'black',
// borderBottomWidth: 3,
position:"absolute",
// display:"flex",
// justifyContent:"flex-end",
// alignItems:"flex-start"
   
},
reportTitle:{
    color: 'black',
    fontSize: 10.8,
    // backgroundColor: '#F2F0F0',
    // border:1,
    fontFamily:'Helvetica-Bold',
    // alignSelf:"flex-end",
    // textAlign:"center"
    // textDecorationLine: 'underline'
    // paddingLeft:5,
    // paddingRight:20,
    //  paddingTop:-15
    // marginTop:1.5
},
  });


  const InvoiceTitleNewGroup = ({title}) => (<>
    {/* 08-Aug-2023 120 ki jga ?124 */}
    <View style={[styles.titleContainer,{top:title.PatientImage!=null?124:115}]}>
    {/* <Text style={[styles.reportTitle,{ fontSize:title.GroupTitleNew=="MPL Pre Employment Medical (Riders/Drivers)"?9.7:title.GroupTitleNew=="MPL Pre Employment Medical (General)"?9.7: 10.5,}]}>{title.GroupTitleNew}</Text> */}
    {/* <Text style={[styles.reportTitle,{ fontSize:title.GroupTitleNew=="MPL Pre Employment Medical (Riders/Drivers)"?9.7:title.GroupTitleNew=="MPL Pre Employment Medical (General)"?9.7:title.GroupTitleNew=="MPL Staff Medical (Kitchen)"? 10.8:8.7}]}>{title.GroupTitleNew}</Text> */}
    <Text style={[styles.reportTitle,{ 
      // fontSize:title.GroupTitleNew=="MPL Pre Employment Medical (Riders/Drivers)"?9.7:title.GroupTitleNew=="MPL Pre Employment Medical (General)"?9.7:title.GroupTitleNew=="MPL Staff Medical (Kitchen)"? 10.8:9.7
      fontSize:8.7
      
      }]}>{title.GroupTitleNew}</Text>

        {/* <Text style={styles.reportTitle}>{title.GroupTitleNew}</Text> */}
    </View>
     
  </>
  );
  
  export default InvoiceTitleNewGroup