import React, { useEffect } from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'
import Comments from './Comments'
import Specimen from './Specimen'
import Result from './Result'
import MedicineHeader from './MedicineHeader'
import CovidPDF from '../Covid_PDF/CovidTemplate'
const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    fontFamily: "Helvetica",
    borderColor: 'black',
  },
  subReportTitle: {
    color: 'black',
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  GermTitle: {
    color: 'black',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  SusProfileTitle: {
    color: 'black',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  MedicineGroupName: {
    color: 'black',
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  }
});


class InvoiceItemsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.invoice,
      line: 2,
      page: 1,
      CrossCheck: {},
      NextPage: 0,
      Garbage: [],
      counter: 0,
      page: 0,
      DefaultComments: {
        "Cobas C311": "Method/Analyzer: Cobas C311, fully automated chemistry analyzer, from Roche Diagnostics, Switzerland.",
        "Cobas C111": "Method/Analyzer: Cobas C311, fully automated chemistry analyzer, from Roche Diagnostics, Switzerland.",
        "Cobas e411": "Method/Analyzer: Cobas e 411, fully automated Electrochemiluminescence immunoassay (ECLIA) based analyzer, from Roche Diagnostics, Switzerland.",
        "Blue Diver Instrument": "Method/Analyzer: Blue Diver Instrument, automated Immunodot analyzer, from D-tek, Belgium.",
        "BC - 5000": "Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "BC - 5150": "Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "Cobas U411": "Method/Analyzer: Cobas u 411, automated Urine analyzer, from Roche Diagnostics, Switzerland.",
        "Cube 30 Touch": "Method/Analyzer: Cube 30 TOUCH, Automatic instrument for ESR, from Diesse Diagnostica Senese, Italy.",
        "GeneXpert": "Method/Analyzer: GeneXpert ® PCR system by Cepheid, USA.",
        "BC6200": "Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "Compact X, Behnk Elektronik": "Method/Analyzer: Thrombolyzer Compact X, , fully automated coagulation analyzer, from Behnk Elektronik GmbH & Co. Germany.",
        "FANhp UBT": "Method/Analyzer: FANhp, An automated Urea Breath Testing analyzer, from Fischer Analysen Instrumente (FAN), Germany.",
        "MiniCap Sebia": "Method/Analyzer: MINICAP FLEX PIERCING, fully automated Capillary Electrophoresis System, from Sebia, France.",
        "Chorus Trio": "Method/Analyzer: Chorus TRIO Instrument, automated ELISA based immunoassay analyzer, from Diesse Diagnostica Senese Spa, Italy.",
        "Manual": "",
        "OST": "",
        "Microlab 300": "",
        "Alegria Instrument": "Method/Analyzer: Alegria Instrument, automated ELISA based immunoassay analyzer, from Orgentec Diagnostika GmbH, Germany.",
        "HG SWIFT": "Method/Analyzer: HG Swift, A loop mediated iso thermal amplification (LAMP), from Hibergene, Ireland.",
        "VIDAS":"Method/Analyzer: Automated immunoassay analyzer (VIDAS) from Biomerieux SA, France."
      },
      Machine: "",
      singleTestCounter: 0,
      MachineCommentsToShow: [],
      ShowMachineComments: true
    }
  }

  render() {

    return (Object.keys(this.state.data.Histopathology).map((key) => {

        var shiftMargin = 0
        var totalTests = 0
        this.state.line = this.state.line + 2

        var subGroupTitle = {}

        this.state.data.Histopathology[key].map((item) => {
          totalTests = totalTests + item.rowHeight
        })

        var FirstBox = this.state.line + this.state.data.Histopathology[key][0].rowHeight + 2

        if (FirstBox > 28) {
          this.state.line = 0
          this.state.NextPage = 1


        } else {
          this.state.NextPage = 0


        }
        if (key.includes("Complete") && this.state.data.Histopathology[key][0].prev1.length > 4) {
          this.state.line = this.state.line - 8
        }
        if (this.state.NextPage == 0) {

          this.state.Garbage.push(1)
        } else {
          this.state.Garbage = []
        }
        if (this.state.Garbage.length == 3) {
          this.state.NextPage = 1
          this.state.line = 0
        }

        if ((key.includes("Complete Blood Picture") || key.includes("Urine Routine Examination")) && this.state.counter > 0) {
          this.state.NextPage = 1
          this.state.line = 0
        }
        // Machine

        this.state.ShowMachineComments = false
        if (this.state.Machine != this.state.data.Machine[key]) {
          this.state.ShowMachineComments = true
          this.state.Machine = this.state.data.Machine[key]

        }
        console.log("Machine : ")
        console.log(this.state.data.Machine[key])
        var pasteMachineComm = this.state.DefaultComments[this.state.data.Machine[key]] == undefined ? this.state.data.MachineComments[key] : this.state.DefaultComments[this.state.data.Machine[key]]

        this.state.MachineCommentsToShow.push(pasteMachineComm)

        console.log(this.state.MachineCommentsToShow)
        // Germ Counter
        var GermCounter = 0
        console.log(key)
        console.log(shiftMargin)
        console.log(this.state.NextPage)
        console.log(this.state.counter)
        console.log(Object.keys(this.state.data.Germs).length)
        console.log("Comments Flag")
        console.log(this.state.ShowMachineComments)
        console.log(this.state.data.MachineComments[key])
        this.state.counter++

        // Test counter
        console.log("Test List Length = ")
        if (this.state.data.Histopathology[key].length == 1) {
          this.state.singleTestCounter++
        } else {
          this.state.singleTestCounter = 0
        }



        return <View>
        <View style={styles.tableContainer}>

          <View style={{
            paddingLeft: 30,
            paddingRight: 30,
          }} >

            {
              this.state.counter > 1 ? <View break={true} key={key + "1"}></View> : null
            }
          
            {
              this.state.data.Histopathology[key].map(item => {
                this.state.line = this.state.line + item.resultLines

                if (this.state.line > 28) {
                  this.state.line = 0
                }

                return <View  >
                  {/* <View break={true} key={item.test_name+"1"}></View> */}
                  <View style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: "100%",
                    lineHeight: 1,
                    fontSize: 7,
                    paddingTop: 5
                  }} key={item.test_name}>

                    <Text style={{

                      width: '100%%',
                      textAlign: 'left',
                      paddingTop: 2,
                      paddingLeft: 8,
                      fontFamily: 'Helvetica-Bold',
                      fontSize: 10,
                    }}>{item.test_name}</Text>


                    <Text style={{

                      width: '100%%',
                      textAlign: 'left',
                      paddingLeft: 8,
                      paddingTop: 5,

                      fontFamily: "Helvetica",
                      fontSize: 9,
                      lineHeight: 1.4

                    }}>{item.result}</Text>


                  </View>


                </View>
              })

            }


          </View>
        </View>
    </View>
      
}))
  }
}
export default InvoiceItemsTable