import React, { useEffect } from 'react';

import { CSVLink } from "react-csv";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import Button from '@material-ui/core/Button';
import * as firebase from 'firebase';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';


import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import ReactExport from 'react-data-export';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { isMoment } from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const columns = [
  { id: 'invoice', label: 'Invoice' },
  { id: 'name', label: 'Passenger Name' },
  { id: 'passport', label: 'Passport' },
  { id: 'flight_no', label: 'Flight#' },
  { id: 'reference_no', label: 'PNR' },
  { id: 'airline', label: 'Airline' },
  { id: 'result', label: 'Result' },
  { id: 'collection_point', label: 'Collection City' },
  { id: 'destination', label: 'Final Destination' },
  { id: 'booking_date_time', label: 'Booking Date/Time' },
  { id: 'result_date_time', label: 'Report Date/Time' },
  { id: 'flight_date_time', label: 'Flight Date/Time' }
];
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}


export default function Reports() {
  const classes = useStyles();
  const [to,setTo]=React.useState(new Date())
  var from_date=new Date()
  from_date.setDate(new Date().getDate()-10)
  console.log(from_date)
  const [from,setFrom]=React.useState(from_date)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(true)
  const [branchDetail, setBranchDetail] = React.useState([]);
  const [data_export, setData_Export] = React.useState([])
  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branch, setBranch] = React.useState('All')
  const [state, setState] = React.useState({
    status: 'All',
  });


  const multiDataSet = [
    {
      columns: [
        { title: "Passenger Name", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },//char width 
        { title: "Passport", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Flight #", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "PNR", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Airline", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Result (Negative/Positive)", width: { wch: 40 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Collection City", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Final Destination", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Registration Date", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Report Date", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Flight Date", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
      ],
      data: data_export
    }
  ];

  const getData = async (data) => {


    var rows_export = []

    var res = await data.map((item) => {
      var obj = [
        { value: item.name, width: { wch: 30 },style: { font: { sz: "14" }, alignment: { horizontal: 'center' } } },
        { value: item.passport, width: { wch: 30 },style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.flight_no, width: { wch: 30 },style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.reference_no, width: { wch: 30 },style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.airline, width: { wch: 30 },style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.result, width: { wch: 30 },style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.collection_point, width: { wch: 30 },style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.destination, width: { wch: 30 },style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.booking_date_time, width: { wch: 30 },style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.result_date_time, width: { wch: 30 },style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.flight_date_time, width: { wch: 30 },style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } }
      ]
      rows_export.push(obj)

      return

    })

    Promise.all(res).then(() => {
      setData_Export(rows_export)
    })
  }
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {

    if (firebase.apps.length === 0) {
    

      fetch('https://reports.mpl-labs.pk:8443/4DACTION/WebLoginAuthorize',{
      method: 'POST',
      'Content-Type':"application/json",
      body:'vUID=180820006&vRole=Patient&vLoginType=ID&vRandom=21211'
    }).then((res)=>res.json()).then( async(res)=>{
     
      if (firebase.apps.length === 0) {
        firebase.initializeApp(res[0]);
    }
   
  })
}
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver"
    ).then((res) => res.json())
      .then((result) => {
        setBranchDetail(result)
        var vSearchStr={
          "PIN":"",
          "PhoneNo":"",
          "PatientName":"",
          "RegDateFrom":from.toISOString(),
          "RegDateTo":to.toISOString(),
          "vBranchID":"0",
          "AirlineID":2,
          "Airline_Type":""
      }
         fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebGetTestsAirlineGeneric",{
          method:"POST",
          'Content-Type':"application/json",
          body:JSON.stringify(vSearchStr)
        }).then((res) => res.json())
          .then(async(result) => {
            
const diffTime = Math.abs(from- to);
const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
var the_rows = []
var srno = 1
console.log(diffDays)
if(diffDays==0){

var filter_date=new Date(to.toISOString())
var month=filter_date.getMonth()+1
var temp_cases={
KSA:0,
AirBlue:0
}
var day=filter_date.getDate()
var selected_date=filter_date.getFullYear()+"-"+month+"-"+day
      firebase.database().ref("ExcelExport/FlyDubai/"+selected_date).once('value', async (response)=>{
        if(response.toJSON()==null){
       
          result.map((data) => {
            var res_date = new Date(data.Result_Date).ddmmyyy()
            var flight_date = new Date(data.Flight_Date).ddmmyyy()
            var test_res = data.Test_Result

            if (data.Flight_Date == "0000-00-00T00:00:00.000Z") {
              flight_date = ""
            }
            if (data.Result_Date == "0000-00-00T00:00:00.000Z") {
              res_date = ""
            }
            if (data.Test_Result == "Covid19 NOT Detected") {
              test_res = 'Negative'
            }
            if (data.Test_Result == "Covid19 Detected") {
              test_res = 'Positive'
            }
            var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
            var booking_time = msToTime(data.ACCESS_TiME)
            if (test_res == "") {
              test_res = "Results not Ready Yet"
            }


            var flight_date = new Date(data.Flight_Date).ddmmyyy()
            var flight_time = msToTime(data.Flight_Time)
            var result_time=msToTime(data.Result_Time)
            var collection_point=data.B_TiTLE.split(',')[1]

            var obj = {
              invoice:data.Invoice_ID,
              name: data.NAME_F + " " + data.NAME_L,
              passport: data.Passport_No,
              flight_no: data.Flight_no,
              reference_no: data.Ticket_no,
              airline:"FZ",
              result: test_res,
              collection_point:collection_point,
              destination: data.Destination,
              booking_date_time: booking_date + " " + booking_time,
              result_date_time: res_date +" "+result_time,
              flight_date_time:flight_date+" "+flight_time
             
            }
           
            the_rows.push(obj)

          })

          setRows(the_rows)
          setOrg_Rows(the_rows)
          setLoading(false)
          
          getData(the_rows)
        }else{
          var arr_string=response.toJSON()
          var arr=JSON.parse(arr_string)
          result.map((data) => {
            if(!arr.includes(data.Invoice_ID)){
              var res_date = new Date(data.Result_Date).ddmmyyy()
                  var flight_date = new Date(data.Flight_Date).ddmmyyy()
                  var test_res = data.Test_Result
    
                  if (data.Flight_Date == "0000-00-00T00:00:00.000Z") {
                    flight_date = ""
                  }
                  if (data.Result_Date == "0000-00-00T00:00:00.000Z") {
                    res_date = ""
                  }
                  if (data.Test_Result == "Covid19 NOT Detected") {
                    test_res = 'Negative'
                  }
                  if (data.Test_Result == "Covid19 Detected") {
                    test_res = 'Positive'
                  }
                  var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
                  var booking_time = msToTime(data.ACCESS_TiME)
                  if (test_res == "") {
                    test_res = "Results not Ready Yet"
                  }
    
    
                  var flight_date = new Date(data.Flight_Date).ddmmyyy()
                  var flight_time = msToTime(data.Flight_Time)
                  var result_time=msToTime(data.Result_Time)
                  var collection_point=data.B_TiTLE.split(',')[1]
    
                  var obj = {
                    invoice:data.Invoice_ID,
                    name: data.NAME_F + " " + data.NAME_L,
                    passport: data.Passport_No,
                    flight_no: data.Flight_no,
                    reference_no: data.Ticket_no,
                    airline:"FZ",
                    result: test_res,
                    collection_point:collection_point,
                    destination: data.Destination,
                    booking_date_time: booking_date + " " + booking_time,
                    result_date_time: res_date +" "+result_time,
                    flight_date_time:flight_date+" "+flight_time
                   
                  }
                 
                  the_rows.push(obj)
            }

          })

          setRows(the_rows)
            setOrg_Rows(the_rows)
            setLoading(false)
            getData(the_rows)
            
        }


    })
}else{
let currentDate = new Date(from.toISOString());
var dates=[]
while (currentDate <= to) {

var month=(currentDate.getMonth()+1)

var day=currentDate.getDate()
var selected_date=currentDate.getFullYear()+"-"+month+"-"+day

     
dates.push(selected_date)  
currentDate.setUTCDate(currentDate.getUTCDate() +1);
}
console.log(dates)
var highlight_invoices=[]
var temp= await dates.map((selected_date)=>{
return firebase.database().ref("ExcelExport/FlyDubai/"+selected_date).once('value',(records)=>{
var str=records.toJSON()
if(str!=null){
var invoices=JSON.parse(str)
highlight_invoices.push(...invoices)
return ""
}
})
})


Promise.all(temp).then(()=>{
console.log(highlight_invoices)
result.map((data) => {
if(!highlight_invoices.includes(data.Invoice_ID)){
  var res_date = new Date(data.Result_Date).ddmmyyy()
  var flight_date = new Date(data.Flight_Date).ddmmyyy()
  var test_res = data.Test_Result

  if (data.Flight_Date == "0000-00-00T00:00:00.000Z") {
    flight_date = ""
  }
  if (data.Result_Date == "0000-00-00T00:00:00.000Z") {
    res_date = ""
  }
  if (data.Test_Result == "Covid19 NOT Detected") {
    test_res = 'Negative'
  }
  if (data.Test_Result == "Covid19 Detected") {
    test_res = 'Positive'
  }
  var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
  var booking_time = msToTime(data.ACCESS_TiME)
  if (test_res == "") {
    test_res = "Results not Ready Yet"
  }


  var flight_date = new Date(data.Flight_Date).ddmmyyy()
  var flight_time = msToTime(data.Flight_Time)
  var result_time=msToTime(data.Result_Time)
  var collection_point=data.B_TiTLE.split(',')[1]

  var obj = {
    invoice:data.Invoice_ID,
    name: data.NAME_F + " " + data.NAME_L,
    passport: data.Passport_No,
    flight_no: data.Flight_no,
    reference_no: data.Ticket_no,
    airline:"FZ",
    result: test_res,
    collection_point:collection_point,
    destination: data.Destination,
    booking_date_time: booking_date + " " + booking_time,
    result_date_time: res_date +" "+result_time,
    flight_date_time:flight_date+" "+flight_time
   
  }
 
  the_rows.push(obj)
}

})

setRows(the_rows)
setOrg_Rows(the_rows)
setLoading(false)
getData(the_rows)

})

}
    
})

      })
  }, [])

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
     
      this.getFullYear(),
      (mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd
    ].join('/');
  };
  return (
    <Paper className={classes.root}>

      <GridContainer style={{ padding: '2em' }}>
      <GridItem xs={6} sm={6} md={3} lg={3}>
          <InputLabel className={classes.label}>
            <b>  From</b>
          </InputLabel>

          <FormControl fullWidth>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
         
          variant="inline"
          format="dd/MM/yyyy"
          value={from}
          minDate={new Date('01/01/2021')}
          maxDate={to}
          onChange={(date)=>{
              setFrom(date)
          }}
      
        />
         </MuiPickersUtilsProvider>
          
          </FormControl>
        </GridItem>
        <GridItem xs={6} sm={6} md={3} lg={3}>
        <InputLabel className={classes.label}>
            <b>  TO</b>
          </InputLabel>

          <FormControl fullWidth>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
         
          variant="inline"
          format="dd/MM/yyyy"
          value={to}
          maxDate={new Date()}
          minDate={from}
          onChange={(date)=>{
         
              
              setTo(date)
                  
            

          }}
      
        />
         </MuiPickersUtilsProvider>
          
          </FormControl>
          </GridItem>



        <GridItem xs={6} sm={6} md={3} lg={3} style={{ margin: 'auto' }} >

          <Button variant="contained" color="primary" 
          disabled={loading}
            onClick={() => {

              if(to=='Invalid Date' || from=='Invalid Date' || to==null || from ==null){
                alert("Invalid Date")
                return
              }
              var vSearchStr={
                "PIN":"",
                "PhoneNo":"",
                "PatientName":"",
                "RegDateFrom":from.toISOString(),
                "RegDateTo":to.toISOString(),
                "vBranchID":"0",
                "AirlineID":2,
                "Airline_Type":""
            }
               fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebGetTestsAirlineGeneric",{
                method:"POST",
                'Content-Type':"application/json",
                body:JSON.stringify(vSearchStr)
              }).then((res) => res.json())
                .then(async(result) => {
                  
const diffTime = Math.abs(from- to);
const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
var the_rows = []
var srno = 1
if(diffDays==0){
 
  var filter_date=new Date(to.toISOString())
  var month=filter_date.getMonth()+1
  var temp_cases={
    KSA:0,
    AirBlue:0
   }
  var day=filter_date.getDate()
  var selected_date=filter_date.getFullYear()+"-"+month+"-"+day
            firebase.database().ref("ExcelExport/FlyDubai/"+selected_date).once('value', async (response)=>{
              if(response.toJSON()==null){
             
                result.map((data) => {
                 
                  var res_date = new Date(data.Result_Date).ddmmyyy()
                  var flight_date = new Date(data.Flight_Date).ddmmyyy()
                  var test_res = data.Test_Result
    
                  if (data.Flight_Date == "0000-00-00T00:00:00.000Z") {
                    flight_date = ""
                  }
                  if (data.Result_Date == "0000-00-00T00:00:00.000Z") {
                    res_date = ""
                  }
                  if (data.Test_Result == "Covid19 NOT Detected") {
                    test_res = 'Negative'
                  }
                  if (data.Test_Result == "Covid19 Detected") {
                    test_res = 'Positive'
                  }
                  var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
                  var booking_time = msToTime(data.ACCESS_TiME)
                  if (test_res == "") {
                    test_res = "Results not Ready Yet"
                  }
    
    
                  var flight_date = new Date(data.Flight_Date).ddmmyyy()
                  var flight_time = msToTime(data.Flight_Time)
                  var result_time=msToTime(data.Result_Time)
                  var collection_point=data.B_TiTLE.split(',')[1]
    
                  var obj = {
                    invoice:data.Invoice_ID,
                    name: data.NAME_F + " " + data.NAME_L,
                    passport: data.Passport_No,
                    flight_no: data.Flight_no,
                    reference_no: data.Ticket_no,
                    airline:"FZ",
                    result: test_res,
                    collection_point:collection_point,
                    destination: data.Destination,
                    booking_date_time: booking_date + " " + booking_time,
                    result_date_time: res_date +" "+result_time,
                    flight_date_time:flight_date+" "+flight_time
                   
                  }
                 
                  the_rows.push(obj)
    
                })

                setRows(the_rows)
                setOrg_Rows(the_rows)
                setLoading(false)
                
                getData(the_rows)
              }else{
                var arr_string=response.toJSON()
                var arr=JSON.parse(arr_string)
                result.map((data) => {
                  if(!arr.includes(data.Invoice_ID)){
                    
                    var res_date = new Date(data.Result_Date).ddmmyyy()
                  var flight_date = new Date(data.Flight_Date).ddmmyyy()
                  var test_res = data.Test_Result
    
                  if (data.Flight_Date == "0000-00-00T00:00:00.000Z") {
                    flight_date = ""
                  }
                  if (data.Result_Date == "0000-00-00T00:00:00.000Z") {
                    res_date = ""
                  }
                  if (data.Test_Result == "Covid19 NOT Detected") {
                    test_res = 'Negative'
                  }
                  if (data.Test_Result == "Covid19 Detected") {
                    test_res = 'Positive'
                  }
                  var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
                  var booking_time = msToTime(data.ACCESS_TiME)
                  if (test_res == "") {
                    test_res = "Results not Ready Yet"
                  }
    
    
                  var flight_date = new Date(data.Flight_Date).ddmmyyy()
                  var flight_time = msToTime(data.Flight_Time)
                  var result_time=msToTime(data.Result_Time)
                  var collection_point=data.B_TiTLE.split(',')[1]
    
                  var obj = {
                    invoice:data.Invoice_ID,
                    name: data.NAME_F + " " + data.NAME_L,
                    passport: data.Passport_No,
                    flight_no: data.Flight_no,
                    reference_no: data.Ticket_no,
                    airline:"FZ",
                    result: test_res,
                    collection_point:collection_point,
                    destination: data.Destination,
                    booking_date_time: booking_date + " " + booking_time,
                    result_date_time: res_date +" "+result_time,
                    flight_date_time:flight_date+" "+flight_time
                   
                  }
                 
                  the_rows.push(obj)
                  }
    
                })

                setRows(the_rows)
                  setOrg_Rows(the_rows)
                  setLoading(false)
                  getData(the_rows)
                  
              }


          })
}else{
  let currentDate = new Date(from.toISOString());
  var dates=[]
  while (currentDate <= to) {
   
  var month=(currentDate.getMonth()+1)

  var day=currentDate.getDate()
  var selected_date=currentDate.getFullYear()+"-"+month+"-"+day

           
    dates.push(selected_date)  
    currentDate.setUTCDate(currentDate.getUTCDate() +1);
  }
  console.log(dates)
  var highlight_invoices=[]
 var temp= await dates.map((selected_date)=>{
  return firebase.database().ref("ExcelExport/FlyDubai/"+selected_date).once('value',(records)=>{
    var str=records.toJSON()
    if(str!=null){
   var invoices=JSON.parse(str)
   highlight_invoices.push(...invoices)
   return ""
    }
})
 })
 var temp_cases={
  KSA:0,
  AirMPL:0,
  AirBlue:0,
  Skipped:0
 }

Promise.all(temp).then(()=>{
  console.log(highlight_invoices)
  result.map((data) => {
    if(!highlight_invoices.includes(data.Invoice_ID)){
      var res_date = new Date(data.Result_Date).ddmmyyy()
      var flight_date = new Date(data.Flight_Date).ddmmyyy()
      var test_res = data.Test_Result

      if (data.Flight_Date == "0000-00-00T00:00:00.000Z") {
        flight_date = ""
      }
      if (data.Result_Date == "0000-00-00T00:00:00.000Z") {
        res_date = ""
      }
      if (data.Test_Result == "Covid19 NOT Detected") {
        test_res = 'Negative'
      }
      if (data.Test_Result == "Covid19 Detected") {
        test_res = 'Positive'
      }
      var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
      var booking_time = msToTime(data.ACCESS_TiME)
      if (test_res == "") {
        test_res = "Results not Ready Yet"
      }


      var flight_date = new Date(data.Flight_Date).ddmmyyy()
      var flight_time = msToTime(data.Flight_Time)
      var result_time=msToTime(data.Result_Time)
      var collection_point=data.B_TiTLE.split(',')[1]

      var obj = {
        invoice:data.Invoice_ID,
        name: data.NAME_F + " " + data.NAME_L,
        passport: data.Passport_No,
        flight_no: data.Flight_no,
        reference_no: data.Ticket_no,
        airline:"FZ",
        result: test_res,
        collection_point:collection_point,
        destination: data.Destination,
        booking_date_time: booking_date + " " + booking_time,
        result_date_time: res_date +" "+result_time,
        flight_date_time:flight_date+" "+flight_time
       
      }
     
      the_rows.push(obj)
    }

  })

  setRows(the_rows)
    setOrg_Rows(the_rows)
    setLoading(false)
    getData(the_rows)
    
})

}
                })
            }}

          >
            Seach<SearchIcon />
          </Button>

        </GridItem>
       
        <GridItem md={3} lg={3} style={{ margin: 'auto', float: 'right' }} >
          <ExcelFile filename="FlyDubai Excel Export" element={<Button
            color="primary"
            style={{ backgroundColor: 'green', color: "white" }}
            onClick={async()=>{
              var Invoice_Wise_Date={}
                var invoices=await rows.map((item)=>{
                  var vir_date=item.result_date_time.split(' ')
                  vir_date=vir_date[0].split('/')
                  console.log(vir_date)
                  var booking_date=vir_date[0]+"-"+parseInt(vir_date[1])+"-"+parseInt(vir_date[2])
                  if(Invoice_Wise_Date[booking_date]==null || Invoice_Wise_Date[booking_date]==undefined){
                    Invoice_Wise_Date[booking_date]=[item.invoice]
                  }else{
                    Invoice_Wise_Date[booking_date].push(item.invoice)
                  }
                     return item.invoice
                })
                
           Promise.all(invoices).then((values)=>{
            
          
                     Object.keys(Invoice_Wise_Date).map((invoice_date)=>{
                     
                                  firebase.database().ref("ExcelExport/FlyDubai/"+invoice_date).once('value', async (data)=>{
                   var res= data.toJSON()
                   console.log(res)
                    if(res==null){
                      
       firebase.database().ref("ExcelExport/FlyDubai/"+invoice_date).set(JSON.stringify(Invoice_Wise_Date[invoice_date]))
                    }else{
                     var temp=JSON.parse(res)
                     console.log(temp)
                     temp.push(...Invoice_Wise_Date[invoice_date])
       firebase.database().ref("ExcelExport/FlyDubai/"+invoice_date).set(JSON.stringify(temp))
                    }
               
                   
                  })
                     })
      
                 
                 
                })

            }}
          >
            Export Excel
              </Button>}>
            <ExcelSheet dataSet={multiDataSet} name="FlyDubai Excel Export" />
          </ExcelFile>

        </GridItem>
      </GridContainer>

      {loading ?
        <center>
          <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
        </center>
        :
        <>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code + Math.random()} >
                      {columns.map((column) => {
                        const value = row[column.id];

                        return (

                          <TableCell key={column.id} align={column.align} >
                            {value}
                          </TableCell>

                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      }
    </Paper>
  );
}
