import React,{useState,useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import picsample from "../../assets/img/airblue.jpeg"
import ReactLoading from 'react-loading';


// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Lab_Tests from "../Lab_Reports/Lab_Tests"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import FreeHS_Msg from '../Components/FreeHS_Msg'
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import useWindowDimensions from "../../useWindowDimensions";


// Sections for this page
// import ProductSection from "./Sections/ProductSection.js";
// import TeamSection from "./Sections/TeamSection.js";
// import WorkSection from "./Sections/WorkSection.js";
import molecule_1 from 'assets/img/animations/virus (13).png';



import molecule_6 from 'assets/img/animations/virus (6).png';

import molecule_7 from 'assets/img/animations/virus (9).png';

import COVID19_PCR from '../COVID19_PCR/Covid19_PCR.js'
import test_info from 'assets/img/test_info.png';


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function NewsAndFeed(props) {
    const [newsAndFeeds, setnewsAndFeeds] = useState([]);
    const [loading, setloading] = useState(true);

  const { width } = useWindowDimensions();


  const classes = useStyles();
  var tesTmg= "data:image/png;base64,";

  const { ...rest } = props;
  useEffect(()=>{
    // window.scrollTo(0,0);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    
   NewsAndFeedsHandler()
    
 },[])
 const NewsAndFeedsHandler =async()=>{
  
    // setloading(true)
      
        var d = new Date()
  
    
    
        var strDate=d.toISOString().slice(0,-5)
         console.log(strDate)
    
          
        
    
            //  let dataFive = await  fetch("http://reports.mpl-labs.pk:8443/4DACTION/WebsiteShowSupEventsNEWS");
             let dataFive = await  fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebsiteShowSupEventsNEWS");
           
            let responseData5 = await dataFive.json();
            console.log('data from the api is Single Products ###### : ',responseData5);

             setnewsAndFeeds(responseData5)
             setloading(false)
            // fetch("http://reports.mpl-labs.pk:8443/4DACTION/WebsiteShowSupEventsNEWS").then(res => res.json()).then((response) => {
            //     // this.setState({Branches : response})
            //     // setnewsAndFeeds()
            //     console.log("Response of API 321 BranchesLoadAllfromWebserver :",response)
      
            //   })
            
       
    
            // console.log('data from the api is Single Products ###### : ',newsAndFeeds);
    
            
         
       }
  return (
    <div style={{backgroundColor:"#196c90"}}>
      <Header
        // color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      {/* <Parallax filter image={require("assets/img/building.jpeg")} style={{marginTop:100}}>
        <div className={classes.container}>
          <GridContainer>
            
            <GridItem>
       
       
           
              <h1 className={classes.title}>News And Feeds</h1>
              <h4>
               Latest News and Feeds that are relevent to MPL.
              </h4>
              <br />
            
            </GridItem>
          </GridContainer>
        </div>
      </Parallax> */}
         <div  >
         <img
                src={require("assets/img_stock/New2023/backgroundFinal7.jpg")}
                style={{ 
                maxWidth: "100%",
                height:width<500?200 : "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                marginTop:width<500?"22%":"8%",
                opacity:width<500?0.5:0.5
            
            }}
              />
              {/* <h1 className={classes.title} style={{position:"absolute"}}>Message From CEO </h1> */}

        {/* <div className={classes.container}>
          <GridContainer>
            
            <GridItem>
            
           
              <h1 className={classes.title} style={{marginTop:-40}}>Message From CEO </h1>
              
            
            
            </GridItem>
          </GridContainer>
        </div> */}
      </div>
      {/* <div className={classNames(classes.main, classes.mainRaised)}> */}
      {/* <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?-150:-200}}> */}
      <div className={classNames(classes.main, classes.mainRaised)} style={{marginTop:width<500?-50:-200}}>

        <div className={classes.container} style={{paddingTop:"1em", paddingBottom:"5em"}} >

          {/* <br />
          <img src={test_info} style={{
  width: '7em',}} class="center"/> */}
  {/* table call ho rha in which api call and fill the data state and display table in this screen */}
            {/* <Lab_Tests /> */}
    <div className='container-fluid  m-0 p-0 w-100'>
        {/* mt-5 tha yhan */}
    <div className='row mt-0 m-0 p-0'>
     
          <div className='col-12 m-0 p-0 d-flex align-items-center justify-content-center'>
            {width<500?
          <h3 className='mb-3'  style={{color:"#3c4858",fontWeight:"bold"}}>News and Feeds</h3>
:
<h1 className='mb-3'  style={{color:"#3c4858",fontWeight:"bold"}}>News and Feeds</h1>

}
    </div>
        
    </div>
    <div className='row d-flex justify-content-center border border-0 border-success m-0 p-0 g-2'>
    {loading ?
          <center>
            <ReactLoading type={'spinningBubbles'} color={'#196c90'} height={'10em'} width={'10em'} />
            {/* <h3 style={{color:"#196c90",fontWeight:"bold"}}>Loading.....</h3> */}
          </center>
          :
     <div className= {width<500?'row w-100 d-flex justify-content-around m-0 p-0 border border-0 border-dark':'row w-75 d-flex justify-content-around m-0 p-0 border border-0 border-dark'} >
     {newsAndFeeds && newsAndFeeds.map((item)=>
 <div class="col-12 col-md-4  mb-3 border border-0 border-danger">
      <div class="p-3 d-flex flex-column align-items-center justify-content-center border bg-light border border-0 border-success shadow" style={{}}>
      <p className='text-center' style={{fontSize:10,fontWeight:"bold",color:"#3c4858"}}>{item.news_date.slice(0,10)}</p>
        <img className='container-fluid mb-2 m-0 p-0'  src={`${tesTmg}`+item.news_picture} alt='test'></img>
        <p className='text-center' style={{fontSize:18,fontWeight:"bold",color:"#3c4858"}}>{item.news_heading}</p>
        <p className='text-center' style={{fontSize:16,color:"#3c4858"}}>{item.news_details}</p>
       

      </div>
    </div>

      )}
     {/* <div class="col-12 col-md-4 mb-3 border border-0 border-danger">
    <div class="p-3 d-flex flex-column align-items-center justify-content-center border bg-light border border-0 border-success shadow">
        <img className='container-fluid mb-3 m-0 p-0' src={picsample} alt='test'></img>
        <h1 className='text-center fw-bold fs-2'>ARE MOODY BARS BANNED?</h1>
    </div>
    </div> */}
     </div> 
}
   </div>

</div>
            
            {/* this is for Free Home Sampling section */}
            {/* <FreeHS_Msg /> */}
         
        </div>
      </div>
      <Footer />
    </div>
  );
}
