import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'white'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: 'black',
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        alignItems: 'center',
        height: 16,
        fontFamily:'Helvetica',
        fontSize:7,
        color:'white',
        textAlign: 'center',
        flexGrow: 1,

    },
    medicine: {
        width: '80%',
        fontSize:8,
        paddingTop:3,
        height:"100%",
        borderWidth:1,
        borderColor:"white"
    },
    result: {
        width: '20%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        fontSize:8,
        paddingTop:3,
        height:"100%",
        borderWidth:1,
        borderColor:"white"
    }
  });

  const InvoiceTableHeader = ({date}) => (
    <View style={styles.container}>
        <Text style={styles.medicine}>Medicine</Text>
        <Text style={styles.result}>Result</Text>
  
    </View>
  );
  
  export default InvoiceTableHeader