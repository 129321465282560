import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
  titleContainer:{
  //   flexDirection: 'row',
  //  justifyContent:'end',
  //  alignItems:"end",
   //margin:2,
   //marginTop:0,
  //  top:110,
   left:28.5,
  top:108,
  // left:26,
    // backgroundColor:"green",
    // height:20,
//     width:180,
// borderStyle:"solid",
// borderBottomColor: 'black',
// borderBottomWidth: 3,
position:"absolute",
// display:"flex",
// justifyContent:"flex-end",
// alignItems:"flex-start"
   
},
reportTitle:{
    color: 'black',
    fontSize: 10.8,
    // backgroundColor: '#F2F0F0',
    // border:1,
    fontFamily:'Helvetica-Bold',
    // alignSelf:"flex-end",
    // textAlign:"center"
    // textDecorationLine: 'underline'
    // paddingLeft:5,
    // paddingRight:20,
    //  paddingTop:-15
    // marginTop:1.5
},
titleContainer1:{
  //   flexDirection: 'row',
  //  justifyContent:'end',
  //  alignItems:"end",
   //margin:2,
   //marginTop:0,
  //  top:110,
   left:28.5,
  top:108,
  marginTop:14,
  // left:26,
    // backgroundColor:"green",
    // height:20,
    width:180,
borderStyle:"solid",
borderBottomColor: 'black',
borderBottomWidth: 1,
position:"absolute",
// display:"flex",
// justifyContent:"flex-end",
// alignItems:"flex-start"
   
},

  });


  const InvoiceTitleNewGroup = ({title,titleLength}) => (<>
    {/* <View style={[styles.titleContainer,{top:title.PatientImage!=null?120:115}]}>
        <Text style={styles.reportTitle}>{title.GroupTitleNew}</Text>
    </View> */}
      {/* <View fixed style={[styles.titleContainer1,{top:title.PatientImage!=null?120:115,width:title.GroupTitleNew =="MPL Staff Medical (Kitchen)"?140:title.GroupTitleNew =="MPL Pre Employment Medical (Riders/Drivers)"?208:180}]}> */}
      <View fixed style={[styles.titleContainer1,{top:title.PatientImage!=null?120:115,
        // width:title.GroupTitleNew =="MPL Staff Medical (Kitchen)"?140:title.GroupTitleNew =="MPL Pre Employment Medical (Riders/Drivers)"?208:title.GroupTitleNew =="MPL Pre Employment Medical (General)"?180:title.GroupTitleNew=="ISH-Pre Employment Medical (Food Handler)"?200:208
        width:titleLength<=18?titleLength+55:titleLength>18&&titleLength<=20?titleLength+65:titleLength>20&&titleLength<=25?titleLength+85:titleLength>25&&titleLength<=27?titleLength+80:titleLength>27&&titleLength<=30?titleLength+95:titleLength>30&&titleLength<=33?titleLength+105:titleLength>33&&titleLength<=36?titleLength+113:titleLength>36&&titleLength<=39?titleLength+126:titleLength>39&&titleLength<=42?titleLength+136:titleLength>42&&titleLength<=45?titleLength+146:titleLength+156
       
       }]}>
      
  </View>
  </>
  );
  
  export default InvoiceTitleNewGroup