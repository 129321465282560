import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'white'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: 'black',
        borderBottomWidth: 0.5,
        borderTopWidth: 0.5,
        borderLeftWidth: 0.5,
        borderRightWidth: 0.5,
        alignItems: 'center',
        height: 16,
        fontFamily:'Helvetica',
        fontSize:7,
        color:'white',
        textAlign: 'center',
        flexGrow: 1,

    },
    description: {
        width: '20%',
        fontSize:7,
        paddingTop:3,
        height:"100%",
        borderWidth:1,
        borderColor:"white"
    },
    qty: {
        width: '20%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        fontSize:7,
        paddingTop:3,
        height:"100%",
        borderWidth:1,
        borderColor:"white"
    },
    rate: {
        width: '20%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        paddingTop:3,
        fontSize:7,
        height:"100%",
        borderWidth:1,
        borderColor:"white"
    },
    unit: {
        width: '10%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        paddingTop:3,
        height:"100%",
        fontSize:7,
        borderWidth:1,
        borderColor:"white"
    },
    prev1: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        fontSize:7,
        paddingTop:3,
        borderWidth:1,
        height:"100%",
        borderColor:"white"
    },
    prev2: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        fontSize:7,
        paddingTop:3,
        height:"100%",
        borderWidth:1,
        borderColor:"white"
    },
  });

  const InvoiceTableHeader = ({data}) => (
    <View style={styles.container}>
        <Text style={styles.description}>Test Name</Text>
        <Text style={styles.qty}>Result {data.reportDate}/{data.reportTime}</Text>
        <Text style={styles.rate}>Ref Range</Text>
        <Text style={styles.unit}>Unit</Text>
        <Text style={styles.prev1}>Previous 1</Text>
        <Text style={styles.prev2}>Previous 2</Text>
  
    </View>
  );
  
  export default InvoiceTableHeader